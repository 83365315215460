import {
  GET_MIMS_REVIEWS, GET_MIMS_REVIEWS_FAIL, GET_MIMS_REVIEWS_SUCCESS,
} from '../Type';

const INITIAL = {
  m_reviews: [],
  get_m_review_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    // MIMS Testimonial Reviews 
    case GET_MIMS_REVIEWS: {
      return {
        ...state,
      }
    }
    case GET_MIMS_REVIEWS_FAIL: {
      return {
        m_reviews: [],
        get_m_review_success: false,
      }
    }
    case GET_MIMS_REVIEWS_SUCCESS: {
      return {
        m_reviews: action.data,
        get_m_review_success: true,
      }
    }

    default:
      return state;
  }
}