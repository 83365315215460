import arrowLeft from "src/Assets/Images/Common/arrow-left.svg";
import arrowRight from "src/Assets/Images/Common/arrow-right.svg";

export const CarouselLeftButton = `
    <div style='width:50px;height:50px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowLeft}' alt='nav-arrow' />
    </div>
`;
export const CarouselRightButton = `
    <div style='width:50px;height:50px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowRight}' alt='nav-arrow' />
    </div>
`;

export const StarSVGViewBox = "0 0 16 15";
export const StarSVGIconPath =
  "M7.90577 0.587492L9.82622 5.13287L14.7428 5.55524C15.0838 5.58469 15.2225 6.01017 14.9637 6.23414L11.2344 9.46512L12.3519 14.2717C12.4294 14.6057 12.0675 14.8684 11.7746 14.6909L7.54927 12.1427L3.32397 14.6909C3.03025 14.8676 2.6691 14.6049 2.7466 14.2717L3.86415 9.46512L0.134072 6.23337C-0.124778 6.00939 0.0131721 5.58392 0.354947 5.55447L5.27155 5.13209L7.192 0.587492C7.3253 0.271292 7.77247 0.271292 7.90577 0.587492Z";

export const PlansInfo = [
  {
    id: "1",
    access: "Total LifeTime Access",
    price: "$399",
    permissions: [
      "Lifetime access to SAT, ACT, ASVAB, TEAS,HESI, ISEE, PSAT, GRE, IRQT, SSAT, PSAT, AFOQT, GMAT",
      "Access to up to 30+ hours of live review sessions with an elite SAT Prep Tutor. This is valued at over $1500 per year, but students will get it for FREE for a lifetime.",
    ]
  },
  {
    id: "2",
    access: "Specific LifeTime Access",
    price: "$149.99",
    permissions: [
      `LLifetime access to 500+ <ProductName> practice questions`,
      "Access to up to 30+ hours of live review sessions with an elite SAT Prep Tutor. This is valued at over $1500 per year, but students will get it for FREE for a lifetime.",
    ]
  },
  {
    id: "3",
    access: "Monthly Access",
    price: "$49.99/month",
    permissions: [
      "Monthly access to 500+ <ProductName> practice questions",
      "Access to up to 30+ hours of live review sessions with an elite SAT Prep Tutor. This is valued at over $1500 per year, but students will get it for FREE for a lifetime."
    ]
  },
  {
    id: "4",
    access: "FREE Access",
    price: "$0.00",
    permissions: [
      "Access to 5 additional practice questions",
    ]
  }
]

export const GetDashboardUrl = (user, token, isMims = false) => {
  return (
    user.role === "student"
      ? `${process.env.REACT_APP_STUDENT_DASHBOARD_URL}auth?token=${token}&mims=${isMims}`
      : `${process.env.REACT_APP_TUTOR_DASHBOARD_URL}?token=${token}`
  )
};