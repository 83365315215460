import React from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";
import TickCircle1 from "src/Assets/Images/Landing/tick-circle-1.svg";
import TickCircle from "src/Assets/Images/Landing/tick-circle.svg";
import HitBg from "src/Assets/Images/Landing/hit-bg.svg";
import TrialHitBg from "src/Assets/Images/Landing/trial-hit-bg.svg";

export default function Plan({
  className = '',
  info,
  discount,
  selectedPlanId,
  onChosePlan,
  currentTopic,
  isSelected
}) {

  // const getPermission = (permission) => {
  //   return permission.replace("<ProductName>", `<${currentTopic?.name}>`);
  // };

  return (
    <div
      className={`
        plan-component shadow-object 
        border-top-${info.id} 
        ${className}
        ${selectedPlanId === info.id ? 'active' : ''}
      `
      }>
      <div className='plan-main-content'>
        <span>{info.name}</span>
        <div className={`price ${discount ? 'disable-price' : ''}`}>{info.price}</div>
        {discount && <div className='discount'>{discount}</div>}
        {selectedPlanId &&
          <div className={`btn-2 chose-btn`} onClick={() => onChosePlan(info)}>Try It For Free</div>
        }
        <div className='split-border' />
        {info.description.map((item, index) => (
          <div className='h-r mb-2' key={index}>
            <ReactSVG src={index === 0 ? TickCircle1 : TickCircle} />
            <div
              className='access-permission'
              dangerouslySetInnerHTML={{ __html: item }}
            />
          </div>
        ))}
      </div>
      {/* {selectedPlanId && selectedPlanId === info.id && ( */}
      {info.id === 2 && (
        <div className='hit-bg'>
          <ReactSVG src={HitBg} />
          <div className='hit-text'>{'HIT'}</div>
        </div>
      )}
      <div className='hit-bg trial-bg'>
        <ReactSVG src={TrialHitBg} />
        <div className='hit-text'>{'FREE 7 days'}</div>
      </div>
    </div >
  );
}
