
import React from 'react';
import './style.scss';
import { Rating } from 'src/Components';
import { VideoItem } from 'src/Components';

export default function TestimonialItem({
  info,
  onPlayVideo
}) {

  return (
    <div className="row testimonial-item-component shadow-object">
      <div className="col-lg-7 left-content">
        <VideoItem
          thumbImg={info.muse_preview_image}
          onClick={()=>onPlayVideo(info.muse_video)}
        />
      </div>
      <div className="col-lg-5 h-c v-r">
        <h3>{info.student_name}</h3>
        <p>{info.student_title}</p>
        <p>{info.student_comment}</p>
        <p>{info.student_city}</p>
        <Rating rating={info.rating} />
      </div>
    </div>
  );
}