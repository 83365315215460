/**
 * Landing
 */
export const GET_TOPIC_LIST = 'GET_TOPIC_LIST';
export const GET_TOPIC_LIST_SUCCESS = 'GET_TOPIC_LIST_SUCCESS';
export const GET_TOPIC_LIST_FAIL = 'GET_TOPIC_LIST_FAIL';

export const SET_CURRENT_TOPIC = 'SET_CURRENT_TOPIC';

export const PRACTICE_NOTIFY = 'PRACTICE_NOTIFY';
export const PRACTICE_NOTIFY_SUCCESS = 'PRACTICE_NOTIFY_SUCCESS';
export const PRACTICE_NOTIFY_FAIL = 'PRACTICE_NOTIFY_FAIL';

/**
 * Modal
 */
export const TOGGLE_REVIEW_MODAL = 'TOGGLE_REVIEW_MODAL';
export const TOGGLE_VIDEO_MODAL = 'TOGGLE_VIDEO_MODAL';
export const TOGGLE_PRACTICE_MODAL = 'TOGGLE_PRACTICE_MODAL';
export const TOGGLE_PLAN_MODAL = 'TOGGLE_PLAN_MODAL';
export const TOGGLE_QUESTION_MODAL = 'TOGGLE_QUESTION_MODAL';
export const TOGGLE_NOTE_MODAL = 'TOGGLE_NOTE_MODAL';

/**
 * Sidebar
 */
export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';

/**
 * HubSpot
 */
export const FROG_UP_HUBSPOT = 'FROG_UP_HUBSPOT';
export const FROG_UP_HUBSPOT_SUCCESS = 'FROG_UP_HUBSPOT_SUCCESS';
export const FROG_UP_HUBSPOT_FAIL = 'FROG_UP_HUBSPOT_FAIL';

/**
* MailChimp
*/
export const MAIL_CHIMP_ACTION = 'MAIL_CHIMP_ACTION';
export const MAIL_CHIMP_ACTION_SUCCESS = 'MAIL_CHIMP_ACTION_SUCCESS';
export const MAIL_CHIMP_ACTION_FAIL = 'MAIL_CHIMP_ACTION_FAIL';

/**
* Plan
*/
export const GET_PLAN = 'GET_PLAN';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAIL = 'GET_PLAN_FAIL';


/**
 * Review
 */
export const GET_ALL_REVIEWS = 'GET_ALL_REVIEWS';
export const GET_ALL_REVIEWS_SUCCESS = 'GET_ALL_REVIEWS_SUCCESS';
export const GET_ALL_REVIEWS_FAIL = 'GET_ALL_REVIEWS_FAIL';


export const GET_MIMS_REVIEWS = 'GET_MIMS_REVIEWS';
export const GET_MIMS_REVIEWS_SUCCESS = 'GET_MIMS_REVIEWS_SUCCESS';
export const GET_MIMS_REVIEWS_FAIL = 'GET_MIMS_REVIEWS_FAIL';


/**
 * Sign In
 */
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

/**
* Sign In
*/
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

/**
* Student Sign Up
*/
export const STUDENT_SIGN_UP = 'STUDENT_SIGN_UP';
export const STUDENT_SIGN_UP_SUCCESS = 'STUDENT_SIGN_UP_SUCCESS';
export const STUDENT_SIGN_UP_FAIL = 'STUDENT_SIGN_UP_FAIL';

/**
* Payment
*/
export const GET_BRAINTREE_KEY = 'GET_BRAINTREE_KEY';
export const GET_BRAINTREE_KEY_SUCCESS = 'GET_BRAINTREE_KEY_SUCCESS';
export const GET_BRAINTREE_KEY_FAIL = 'GET_BRAINTREE_KEY_FAIL';

export const NONCE_UPDATE = 'NONCE_UPDATE';
export const NONCE_UPDATE_SUCCESS = 'NONCE_UPDATE_SUCCESS';
export const NONCE_UPDATE_FAIL = 'NONCE_UPDATE_KEY_FAIL';

export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const UPDATE_PURCHASE_SUCCESS = 'UPDATE_PURCHASE_SUCCESS';
export const UPDATE_PURCHASE_FAIL = 'UPDATE_PURCHASE_KEY_FAIL';

export const GET_DISCOUNT = 'GET_DISCOUNT';
export const GET_DISCOUNT_SUCCESS = 'GET_DISCOUNT_SUCCESS';
export const GET_DISCOUNT_FAIL = 'GET_DISCOUNT_KEY_FAIL';

/**
* UpSell
*/
export const GET_UPSELL_CONDITION = 'GET_UPSELL_CONDITION';
export const GET_UPSELL_CONDITION_SUCCESS = 'GET_UPSELL_CONDITION_SUCCESS';
export const GET_UPSELL_CONDITION_FAIL = 'GET_UPSELL_CONDITION_KEY_FAIL';

export const PURCHASE_UPSELL = 'PURCHASE_UPSELL';
export const PURCHASE_UPSELL_SUCCESS = 'PURCHASE_UPSELL_SUCCESS';
export const PURCHASE_UPSELL_FAIL = 'PURCHASE_UPSELL_KEY_FAIL';

/**
* Subject
*/
export const GET_SUBJECTS = 'GET_SUBJECTS';
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_FAIL = 'GET_SUBJECTS_FAIL';

/**
* Get Questions
*/
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAIL = 'GET_QUESTIONS_FAIL';

export const GET_TEST_ID = 'GET_TEST_ID';
export const GET_TEST_ID_SUCCESS = 'GET_TEST_ID_SUCCESS';
export const GET_TEST_ID_FAIL = 'GET_TEST_ID_FAIL';

export const GET_SCORES = 'GET_SCORES';
export const GET_SCORES_SUCCESS = 'GET_SCORES_SUCCESS';
export const GET_SCORES_FAIL = 'GET_SCORES_FAIL';

export const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
export const SUBMIT_ANSWER_SUCCESS = 'SUBMIT_ANSWER_SUCCESS';
export const SUBMIT_ANSWER_FAIL = 'SUBMIT_ANSWER_FAIL';

/**
* Other
*/
export const GET_FAQ = 'GET_FAQ';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAIL = 'GET_FAQ_FAIL';

export const SET_REFERRAL_CODE = 'SET_REFERRAL_CODE';
export const SET_QUESTION_STEP = 'SET_QUESTION_STEP';


