import React from 'react';
import './style.scss';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';

export default function CircleTimer({
  key,
  isCountDownTime = true,
  duration,
  getAnswerTime,
  activeCountUp,
  onTimeUpUpdate
}) {

  return (
    <div className='circle-timer-component'>
      <CountdownCircleTimer
        key={key}
        isPlaying={isCountDownTime}
        duration={duration}
        colors={['#22A4EF', '#F7B801', '#A30000', '#A30000']}
        colorsTime={[60, 50, 30, 0]}
        size={80}
        trailStrokeWidth={10}
        strokeWidth={10}
        onUpdate={(remainingTime) => getAnswerTime(duration - remainingTime)}
      >
        {({ remainingTime }) =>
          <div className='time'>
            {!activeCountUp ? `00:${remainingTime < 10 ? `0${remainingTime}` : remainingTime}`
              :
              <div className='v-c count-up'>
                <div> - </div>
                <Timer active={activeCountUp} duration={1000000000000} onTimeUpdate={(e) => onTimeUpUpdate(e.time)}>
                  <Timecode />
                </Timer>
              </div>
            }
          </div>
        }
      </CountdownCircleTimer>
    </div>
  );
}