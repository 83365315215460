import {
  GET_BRAINTREE_KEY,
  NONCE_UPDATE,
  UPDATE_PURCHASE,
  GET_DISCOUNT,
} from '../Type';

export const getBraintreeKey = () => {
  return {
    type: GET_BRAINTREE_KEY,
    data: {}
  }
}

export const nonceUpdate = (data) => {
  return {
    type: NONCE_UPDATE,
    data
  }
}

export const updatePurchase = (data) => {
  return {
    type: UPDATE_PURCHASE,
    data
  }
}

export const getDiscount = (data) => {
  return {
    type: GET_DISCOUNT,
    data
  }
}
