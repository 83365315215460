/* @flow */

import { combineReducers } from 'redux';

import Modal from './Modal';
import Sidebar from './Sidebar';
import Topic from './Topic';
import QA from './QA';
import Notify from './Notify';
import Plan from './Plan';
import Review from './Review';
import ReviewT from './ReviewT';
import Auth from './Auth';
import Subject from './Subject';
import Other from './Other';
import HubSpot from './HubSpot';

import Payment from './Payment';
import UpSell from './UpSell';
import MailChimp from './MailChimp';

export default combineReducers({
    Modal,
    Sidebar,
    Topic,
    QA,
    Notify,
    Plan,
    Review,
    ReviewT,
    Auth,
    Subject,
    Other,
    HubSpot,
    Payment,
    UpSell,
    MailChimp,
})