import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from 'src/Assets/Images/Landing/close-icon-2.svg';
import ArrowIcon from 'src/Assets/Images/Landing/arrow-circle-icon.svg';
import GirlImg from 'src/Assets/Images/Landing/hi-girl-img.png';
import { togglePracticeModal, practiceNotify } from 'src/Redux/Actions';
import { FormInput } from 'src/Components';
import {
  NameValidation,
  EmailValidation,
} from 'src/Utils/Validation';
import { hideSpinner, showSpinner, showToast } from 'src/Utils/Helper';

export default function PracticeModal() {
  const dispatch = useDispatch();

  const getLoadingRef = useRef(false);

  const {
    isPracticeModal
  } = useSelector((state) => state.Modal);

  const {
    practiceNotifyStatus, practiceNotify_success, practiceNotify_loading
  } = useSelector(
    state => state.Notify,
  );

  const {
    currentTopic
  } = useSelector(
    state => state.Topic,
  );

  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    tag: currentTopic.name
  });

  const [userValidation, setUserValidation] = useState({
    nameValid: '',
    emailValid: '',
  });

  useEffect(() => {
    if (getLoadingRef.current && !practiceNotify_loading) {
      hideSpinner();
      if (practiceNotify_success) {
        showToast('success', 3000, practiceNotifyStatus);
      } else {
        showToast('error', 3000, practiceNotifyStatus);
      };
    }
    getLoadingRef.current = practiceNotify_loading;
  }, [practiceNotify_loading, practiceNotify_success, practiceNotifyStatus]);


  const closeModal = () => {
    dispatch(togglePracticeModal(false));
  };

  const onCancel = () => closeModal();

  const onChangeUser = (field, validationField, value) => {
    setUserInfo({
      ...userInfo,
      [field]: value,
    });
    setUserValidation({
      ...userValidation,
      [validationField]: getValidation(field, value)
    });
  };

  const getValidation = (field, value) => {
    if (field === 'email')
      return EmailValidation(value);
    return NameValidation(value);
  };

  const checkDisableBtn = () => {
    if (
      NameValidation(userInfo.name) &&
      EmailValidation(userInfo.email)
    )
      return false;
    return true;
  }

  const onNotify = () => {
    if (!checkDisableBtn()) {
      closeModal();
      showSpinner();
      dispatch(practiceNotify(userInfo));
    }
  }

  return (
    <Modal show={isPracticeModal} className="practice-modal-component">
      <div className="practice-content v-r">
        <div className="close-btn">
          <ReactSVG src={CloseIcon} className='icon-close' onClick={closeModal} />
        </div>
        <div className='practice-main-content'>
          <img src={GirlImg} alt="girl" className='girl-img show-web' />
          <div className='modal-title text-center mb-4'>
            This content is not yet available.
            If you want us to notify you once content becomes available,
            you should enter your email and name below.
          </div>
          <div className='col-lg-12 mb-4' >
            <FormInput
              placeholder={"Enter your name"}
              className='practice-pop-up-form'
              value={userInfo.name}
              validation={userValidation.nameValid}
              onChange={e => onChangeUser('name', 'nameValid', e)}
            />
          </div>
          <div className='col-lg-12 mb-4' >
            <FormInput
              placeholder={"Enter your e-mail"}
              className='practice-pop-up-form'
              value={userInfo.email}
              validation={userValidation.emailValid}
              onChange={e => onChangeUser('email', 'emailValid', e)}
            />
          </div>
          <div className='submit-btn v-c space-between'>
            <div className='btn-2 cancel-btn' onClick={onCancel} >CANCEL</div>
            <div className={`btn-2 ${checkDisableBtn() ? 'disable' : ''}`} onClick={onNotify} >NOTIFY</div>
            <img src={ArrowIcon} alt='arrow-icon' className='arrow-icon show-web' />
          </div>
        </div>
      </div>
    </Modal>
  );
}