import React from 'react';
import './style.scss';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from 'src/Assets/Images/Landing/close-icon.svg';
import { togglePlanModal } from 'src/Redux/Actions';
import { MPlan } from 'src/Components';

export default function PlanModal() {
  const dispatch = useDispatch();

  const {
    isPlanModal
  } = useSelector((state) => state.Modal);

  const closeModal = () => {
    dispatch(togglePlanModal(false));
  };

  return (
    <Modal show={isPlanModal} className="plan-modal-component">
      <div className="plan-modal-content v-r">
        <div className="close-btn">
          <img
            src={CloseIcon}
            alt="close-icon"
            className="close-icon"
            onClick={closeModal}
          />
        </div>
        <div className='plan-main-content'>
          <MPlan />
        </div>
      </div>
    </Modal>
  );
}
