import React, { useEffect, useState, } from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";
import Confetti from 'react-confetti'
import CheckIcon from "src/Assets/Images/Landing/check-icon.svg";
// import VideoPlayIcon from "src/Assets/Images/Landing/play.svg";
// import VideoThumbImg from "src/Assets/Images/Landing/believe-practice-thumb.png";
import EmojiLaughIcon from "src/Assets/Images/Landing/emoji-laugh.png";
import EmojiCryIcon from "src/Assets/Images/Landing/emoji-cry.png";
import CelebrationAudio from "src/Assets/Audio/celebration-effect.mp3";
import { CustomToggle, VideoItem } from 'src/Components';
import _ from 'lodash'

export default function AnswerItem({
  className = '',
  index,
  selectedIndex,
  item,
  isAnswerTrue,
  onSelectAnswer,
  thumbImg,
  onSeeVideo,
  explanation
}) {

  const [showCelebrate, setShowCelebrate] = useState(false)
  const [showVideoExp, setShowVideoExp] = useState(true)

  const canToggleExpMode = () => {
    return !_.isEmpty(explanation) && !_.isEmpty(thumbImg)
  }

  function ExplanationComoponent() {
    if(canToggleExpMode()) {
      return (showVideoExp ? VideoComponent : TextComponent)
    } else if(_.isEmpty(thumbImg)) {
      return (TextComponent)
    } else {
      return (VideoComponent)
    }
  }

  const VideoComponent = <VideoItem thumbImg={thumbImg} onClick={onSeeVideo} className='mt-3' />
  
  const TextComponent = <div dangerouslySetInnerHTML={{ __html: explanation }} style={{ paddingTop: '15px' }}></div>

  useEffect(() => {
    if (isCorrectAnswer()) {
      setShowCelebrate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnswerTrue]);

  useEffect(() => {
    if (showCelebrate) {
      setTimeout(function () {
        setShowCelebrate(false);
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCelebrate]);

  const isCorrectAnswer = () => {
    if (selectedIndex === index && isAnswerTrue === 1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={`answer-item-component ${className}`}>
      <div
        className={`answer 
          ${isCorrectAnswer() ? 'correct-answer-content' : ''}
          ${selectedIndex === index && isAnswerTrue === 0 ? 'inCorrect-answer-content' : ''}`
        }
        onClick={() => onSelectAnswer(item, index)}>
        <div className='answer-title v-c'>
          <div
            className={`check-content center 
              ${selectedIndex === index ? 'select-check-content' : ''}
              ${isCorrectAnswer() ? 'correct-check-content' : ''}
              ${selectedIndex === index && isAnswerTrue === 0 ? 'inCorrect-check-content' : ''}`
            }
          >
            <ReactSVG src={CheckIcon} />
          </div>
          <div className=''>{item}</div>
        </div>
        {/* {selectAnswerId === item.id && (
          <div className='answer-video v-c' onClick={() => onSeeVideo(item)}>
            <ReactSVG src={VideoPlayIcon} />
            <div className='see-video'>See video explanation</div>
          </div>
        )} */}
        {selectedIndex === index && (
          <>
            <ExplanationComoponent />
            { canToggleExpMode() && <div style={{ 
              width: '100%', display: 'flex', justifyContent: 'center'
            }}>
              <CustomToggle 
                value={showVideoExp} 
                onChange={(value) => setShowVideoExp(value)} 
                className={`${ isCorrectAnswer() ? 'correct' : ''}`}
                isOnValue="Text"
                isOffValue="Video"
              />
            </div> }
          </>
        )}
        {selectedIndex === index && (
          <>
            <img src={isAnswerTrue === 1 ? EmojiLaughIcon : EmojiCryIcon} className='funny-icon-1' alt='funny-icon-1' />
            <img src={isAnswerTrue === 1 ? EmojiLaughIcon : EmojiCryIcon} className='funny-icon-2' alt='funny-icon-2' />
            <img src={isAnswerTrue === 1 ? EmojiLaughIcon : EmojiCryIcon} className='funny-icon-3' alt='funny-icon-3' />
          </>
        )}
      </div>
      {showCelebrate && <Confetti
        width={window.innerWidth}
        height={window.innerHeight * 2}
      />}
      {showCelebrate && <iframe
        src={CelebrationAudio}
        height="0"
        title='tutor video'
        allowFullScreen>
      </iframe>}
    </div>
  );
}