import React from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import EBook from 'src/Assets/Images/Landing/Ebook/eBook.svg';
import PdfIcon from 'src/Assets/Images/Landing/Ebook/pdf-icon.svg';
import DownloadIcon from 'src/Assets/Images/Landing/Ebook/download-icon.svg';
import { togglePlanModal } from 'src/Redux/Actions';

const content = `
  <h5>You're probably wondering why. <strong>Here's the reason:</strong></h5>
  <p>The organizations responsible for developing and administering the SAT and ACT tests typically keep their past test materials private. <strong>This is because they frequently rotate and reuse questions and concepts in future tests.</p>
  <p>To bring you these comprehensive compilations of past SAT and ACT questions and answer sheets, we've had to go to great lengths. <strong>It's a rare and valuable insight into the tests' real content, and we're thrilled to share it with you.</strong></p>
  <p>However, we can't guarantee how long these eBooks will be available <strong>for free.</strong> Our mission is to ensure as many students as possible have access to these essential resources as they prep for their exams. But the circumstances may change, and this opportunity might be gone in an instant.</p> 
  <p>Even if you don't have an immediate use for these books, we strongly recommend securing your copies <strong>NOW.</strong> Don't risk losing this rare opportunity to claim this invaluable resource.</p>
  <p>So, stop waiting! Click the green button to secure your <strong>free copies now!</strong></p>
`;

export default function FreeEBook() {
  const dispatch = useDispatch();

  const onDownload = () => dispatch(togglePlanModal(true));

  return (
    <div className="free-book-component landing-part">
      <div className="container">
        <div className="row free-book-content">
          <div className="col-lg-7 left-content">
            <div className='v-c'>
              <h1>Secure Your Free Copies Today</h1>
              <img src={PdfIcon} alt='pdf-icon' />
            </div>
            <h2>We might not be able to provide them for long</h2>
            <div
              className={`list-content`}
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className='btn-2 download-btn v-c' onClick={onDownload} >
              <img src={DownloadIcon} alt='download-icon' />
              <div className='down-btn-text'>DOWNLOAD BOOK FOR FREE</div>
            </div>
          </div>
          <div className="col-lg-5 center right-content">
            <img src={EBook} alt='e-book' className='ebook-img' />
          </div>
        </div>
      </div>
    </div>
  );
}