import {
  FROG_UP_HUBSPOT, FROG_UP_HUBSPOT_SUCCESS, FROG_UP_HUBSPOT_FAIL,
} from '../Type';

const INITIAL = {
  frogHubSpotRes: '',
  frogHubSpot_loading: false,
  frogHubSpot_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Frog Hubspot
    */
    case FROG_UP_HUBSPOT: {
      return {
        ...state,
        frogHubSpotRes: '',
        frogHubSpot_loading: true,
        frogHubSpot_success: false,
      }
    }
    case FROG_UP_HUBSPOT_FAIL: {
      return {
        frogHubSpotRes: action.data.message,
        frogHubSpot_loading: false,
        frogHubSpot_success: false,
      }
    }
    case FROG_UP_HUBSPOT_SUCCESS: {
      return {
        frogHubSpotRes: action.data.message,
        frogHubSpot_loading: false,
        frogHubSpot_success: true,
      }
    }

    default:
      return state;
  }
}