import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";
import { useNavigate } from 'react-router-dom';
import FrameIcon from "src/Assets/Images/Landing/frame.svg";
import { AnswerItem, CircleTimer, QuestionTags } from 'src/Components';
import {
  toggleVideoModal,
  submitAnswer,
  togglePlanModal,
  // toggleQuestionModal,
  toggleNoteModal,
  setQuestionStep,
} from 'src/Redux/Actions';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';

export default function QuestionAnswerFlow({
  className = '',
  testId,
  topic_id,
  questions,
  isLanding,
  onGetAnswerScours,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getLoadingRef = useRef(false);

  const {
    submitAnswerStatus, get_submitAnswer_success, get_submitAnswer_loading,
  } = useSelector(
    state => state.QA,
  );

  const {
    questionStep,
  } = useSelector(
    state => state.Other,
  );

  const [answerStep, setAnswerStep] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
  const [isAnswerTrue, setIsAnswerTrue] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [preSelectedIndex, setPreSelectedIndex] = useState(undefined);
  const [isCountDownTime, setIsCountDownTime] = useState(true);
  const [answerTime, setAnswerTime] = useState(0);
  const [activeCountUp, setActiveCountUp] = useState(false);
  const [countUpTime, setCountUpTime] = useState(0);

  useEffect(() => {
    setCurrentQuestion(questions[answerStep])
  }, [answerStep, questions]);

  useEffect(() => {
    setAnswerStep(questionStep);
  }, [dispatch, questionStep,]);

  //Get Submit Answer res
  useEffect(() => {
    if (getLoadingRef.current && !get_submitAnswer_loading) {
      hideSpinner();
      if (get_submitAnswer_success) {
        setIsAnswerTrue(submitAnswerStatus);
        setSelectedIndex(preSelectedIndex);
        onGetAnswerScours();
      }
    }
    getLoadingRef.current = get_submitAnswer_loading;
  }, [get_submitAnswer_loading, get_submitAnswer_success, onGetAnswerScours, preSelectedIndex, submitAnswerStatus]);

  const onSelectAnswer = (answer, index) => {
    setIsCountDownTime(false);
    setActiveCountUp(false);
    setPreSelectedIndex(index);
    const payload = {
      test_id: testId,
      question_id: currentQuestion.id,
      option: answer,
      timer_second: answerTime + countUpTime
    }
    showSpinner();
    dispatch(submitAnswer(payload));
  };

  const onBack = () => {
    if (answerStep !== 0) {
      setAnswerStep(answerStep - 1);
    }
  };

  // const onSkip = () => {
  //   onNext();
  // };

  const onNext = () => {
    if (isLanding) {
      navigate(`/question-answer/topic_id/${topic_id}`);
      dispatch(setQuestionStep(1));
      // dispatch(toggleQuestionModal(true));
    } else {
      onShowNextQuestion();
    }
  };

  const onShowNextQuestion = () => {
    if (questions.length - 1 > answerStep) {
      if (answerStep < 4) {
        setAnswerStep(answerStep + 1);
        setIsAnswerTrue(undefined);
        setSelectedIndex(undefined);
      } else {
        dispatch(togglePlanModal(true));
      }
    }
  }

  const onSeeVideo = (answer) => {
    dispatch(toggleVideoModal(true, { videoId: currentQuestion.muse_video }));
  };

  const onNote = () => {
    dispatch(toggleNoteModal(true, currentQuestion.notes));
  }

  const getAnswerTime = (time) => {
    setAnswerTime(time)
    if (time === currentQuestion.timer) {
      setActiveCountUp(true)
    }
  }

  const onTimeUpUpdate = (time) => {
    setCountUpTime(time / 1000)
  }

  return (
    <div className={`question-answer-flow-component ${className}`}>
      <div className='sub-header-content'>
        <QuestionTags
          questionDate={currentQuestion.question_date}
          concept={currentQuestion.concept}
          rules={currentQuestion.rules}
        />
      </div>
      <div className='questions-answer-content'>
        <div className='circle-timer'>
          <CircleTimer
            isCountDownTime={isCountDownTime}
            duration={currentQuestion.timer}
            key={currentQuestion.id}
            getAnswerTime={getAnswerTime}
            activeCountUp={activeCountUp}
            onTimeUpUpdate={onTimeUpUpdate}
          />
        </div>
        <ReactSVG src={FrameIcon} className='frame-icon' onClick={onNote} />
        <div className='question-content v-c h-c'>
          {currentQuestion.picture && (
            <img src={currentQuestion.picture} alt='question-img' className='question-img' />
          )}
        </div>
        <div className='answer-container'>
          {/* <h3>{currentQuestion.name}</h3> */}
          <div
            dangerouslySetInnerHTML={{ __html: currentQuestion.description }}
          />
          <div className='answer-content'>
            {currentQuestion.options.map((item, index) => (
              <AnswerItem
                key={index}
                index={index}
                item={item}
                explanation={currentQuestion.explanation_text}
                thumbImg={currentQuestion.muse_video_preview}
                selectedIndex={selectedIndex}
                isAnswerTrue={isAnswerTrue}
                onSelectAnswer={onSelectAnswer}
                onSeeVideo={onSeeVideo}
              />
            ))}
          </div>
        </div>
        <div className='btn-content v-c space-between'>
          <div className='btn-2 back-btn' onClick={onBack} >BACK</div>
          <div className='step-num'><strong>{answerStep + 1}</strong>{` of ${questions.length}`}</div>
          <div className='v-c'>
            {/* <div className='btn-2 skip-btn' onClick={onSkip} >Skip</div> */}
            <div className='btn-2' onClick={onNext} >NEXT</div>
          </div>
        </div>
      </div>
    </div>
  );
}