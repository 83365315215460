import {
  GET_TOPIC_LIST, GET_TOPIC_LIST_FAIL, GET_TOPIC_LIST_SUCCESS,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  getTopicList as getTopicListApi,
} from 'src/Api';

/**
* Get Landing Info
*/
function* getTopicList() {
  try {
    const result = yield getTopicListApi();
    if (result.data) {
      yield put({ type: GET_TOPIC_LIST_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_TOPIC_LIST_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetTopicList() {
  yield takeLatest(GET_TOPIC_LIST, getTopicList);
}

