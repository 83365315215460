import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
// import ActiveIcon from 'src/Assets/Images/Auth/UpSelling/act-active-img.svg'
// import InActiveIcon from 'src/Assets/Images/Auth/UpSelling/act-active-img.svg'
import ArrowIcon from 'src/Assets/Images/Auth/arrow.svg'
import GirlIcon from 'src/Assets/Images/Auth/UpSelling/girl.png'
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import {
  getUpSellCondition,
  purchaseUpSell,
} from 'src/Redux/Actions';
import { GetDashboardUrl } from 'src/Constant';
import {
  UpSellingContainer,
  UpSellingItem,
} from 'src/Components';

export default function PaymentThankYouUpSellPage() {
  const dispatch = useDispatch();

  const GetLoadingRef = useRef(false);
  const PurchaseLoadingRef = useRef(false);

  const {
    upSellCondition, get_upSellCondition_loading, get_upSellCondition_success,
    upSellPurchaseStatus, upSellPurchase_loading, upSellPurchase_success,
  } = useSelector(
    state => state.UpSell,
  );

  const {
    user, token
  } = useSelector(
    state => state.Auth,
  );

  const [selectedUpSellId, setSelectedUpSellId] = useState('');

  useEffect(() => {
    dispatch(getUpSellCondition({ condition: 2 }));
  }, [dispatch]);

  //Get UpSell Condition Res
  useEffect(() => {
    if (GetLoadingRef.current && !get_upSellCondition_loading) {
      hideSpinner();
      if (get_upSellCondition_success) {
        // console.log("===upSellCondition==", upSellCondition)
      } else {
        showToast("error", 3000, upSellCondition)
      }
    }
    GetLoadingRef.current = get_upSellCondition_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_upSellCondition_loading, get_upSellCondition_success, upSellCondition]);

  //UpSell Purchase res
  useEffect(() => {
    if (PurchaseLoadingRef.current && !upSellPurchase_loading) {
      hideSpinner();
      if (upSellPurchase_success) {
        showToast("success", 3000, upSellPurchaseStatus)
        const isMims = true;
        window.location.href = GetDashboardUrl(user, token, isMims);
      } else {
        showToast("error", 3000, upSellPurchaseStatus)
      }
    }
    PurchaseLoadingRef.current = upSellPurchase_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upSellPurchase_loading, upSellPurchase_success, upSellPurchaseStatus, user, token]);

  const onGoToDashboard = () => {
    if (selectedUpSellId) {
      showSpinner();
      upSellPurchase();
    } else {
      const isMims = true;
      window.location.href = GetDashboardUrl(user, token, isMims);
    }
  };

  const upSellPurchase = () => {
    const payload = {
      mims_upsell_id: selectedUpSellId
    }
    dispatch(purchaseUpSell(payload));
  };

  const onChangeUpSell = (id) => {
    setSelectedUpSellId(selectedUpSellId === id ? '' : id);
  };

  return (
    <UpSellingContainer
      title="Make ACT Math Make Sense"
      className='payment-thank-you-up-sell-page'
    >
      {!_.isEmpty(upSellCondition) && upSellCondition.map((item, index) => (
        <UpSellingItem
          key={index}
          isUpSell={!!(selectedUpSellId === item.id)}
          info={item}
          onChange={onChangeUpSell}
        />
      ))}
      <div className='next-btn-content'>
        <button
          className={`done-btn btn-2`}
          onClick={onGoToDashboard}>
          {`${selectedUpSellId ? "MAKE PAYMENT" : "GO TO DASHBOARD"}`}
        </button>
        <img src={ArrowIcon} alt='arrow-icon' className='arrow-icon show-web' />
      </div>
      <img src={GirlIcon} alt='girl-icon' className='girl-icon' />
    </UpSellingContainer>
  );
}
