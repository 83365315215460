import {
  GET_UPSELL_CONDITION, GET_UPSELL_CONDITION_SUCCESS, GET_UPSELL_CONDITION_FAIL,
  PURCHASE_UPSELL, PURCHASE_UPSELL_SUCCESS, PURCHASE_UPSELL_FAIL,
} from '../Type';

const INITIAL = {
  upSellCondition: [],
  get_upSellCondition_loading: false,
  get_upSellCondition_success: false,

  upSellPurchaseStatus: '',
  upSellPurchase_loading: false,
  upSellPurchase_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get BrainTree Key
    */
    case GET_UPSELL_CONDITION: {
      return {
        ...state,
        get_upSellCondition_loading: true,
        get_upSellCondition_success: false,
      }
    }
    case GET_UPSELL_CONDITION_FAIL: {
      return {
        ...state,
        upSellCondition: [],
        get_upSellCondition_loading: false,
        get_upSellCondition_success: false,
      }
    }
    case GET_UPSELL_CONDITION_SUCCESS: {
      return {
        ...state,
        upSellCondition: action.data.data,
        get_upSellCondition_loading: false,
        get_upSellCondition_success: true,
      }
    }

    /**
     * Update Purchase
    */
    case PURCHASE_UPSELL: {
      return {
        ...state,
        upSellPurchase_loading: true,
        upSellPurchase_success: false,
      }
    }
    case PURCHASE_UPSELL_FAIL: {
      return {
        ...state,
        upSellPurchaseStatus: action.data.error,
        upSellPurchase_loading: false,
        upSellPurchase_success: false,
      }
    }
    case PURCHASE_UPSELL_SUCCESS: {
      return {
        ...state,
        upSellPurchaseStatus: action.data.message,
        upSellPurchase_loading: false,
        upSellPurchase_success: true,
      }
    }

    default:
      return state;
  }
}