import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
// import _ from 'lodash'
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormInput, AuthContainer } from 'src/Components';
import { EmailValidation } from 'src/Utils/Validation';
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import { signIn } from 'src/Redux/Actions';
import { GetDashboardUrl } from 'src/Constant';

export default function SignInPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getLoadingRef = useRef(false);
  const {
    user, token, login_res, login_success, login_loading
  } = useSelector(
    state => state.Auth,
  );
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
  });
  const [userValidation, setUserValidation] = useState({
    emailValid: '',
    passwordValid: '',
  });

  useEffect(() => {
    if (getLoadingRef.current && !login_loading) {
      hideSpinner();
      if (login_success) {
        //SignIn Success
        if (user.role === 'tutor') {
          // if (_.isEmpty(user.schedule)) {
          //   var signup_progress = user.training_status === 11 ? 3 : 2;
          //   navigate({
          //     pathname: "/tutor-signup",
          //     isUser: true,
          //     user,
          //     signup_progress
          //   });
          // } else {
          //   window.location.href = GetDashboardUrl(user, token);
          // };
          window.location.href = GetDashboardUrl(user, token);
        };

        if (user.role === 'student') {
          window.location.href = GetDashboardUrl(user, token);
          // if (user.can_book_trial || user.plan_frequency || user.trial_lesson.status === 'pending') {
          //   window.location.href = GetDashboardUrl(user, token);
          // } else {
          // navigate('/sign-up/step-6');
          // };
        };
      } else {
        //SignIn Failed
        showToast('error', 3000, login_res);
      };
    }
    getLoadingRef.current = login_loading;
  }, [dispatch, login_res, login_loading, login_success, token, user, navigate]);

  const onChangeUser = (field, value) => {
    setUserInfo({
      ...userInfo,
      [field]: value,
    });
    if (field === 'email') {
      setUserValidation({
        ...userValidation,
        emailValid: EmailValidation(value)
      });
    }
  };

  const checkDisableBtn = () => {
    if (
      EmailValidation(userInfo.email) &&
      userInfo.password
    )
      return false;
    return true;
  };

  const doSignIn = () => {
    showSpinner();
    dispatch(signIn(userInfo));
  };

  return (
    <AuthContainer>
      <div className="login-component">
        <h1>Sign In</h1>
        <FormInput
          placeholder={"Email"}
          className='auth-input'
          value={userInfo.email}
          validation={userValidation.emailValid}
          onChange={e => onChangeUser('email', e)}
        />
        <FormInput
          placeholder={"Password"}
          className='auth-input'
          value={userInfo.password}
          type={'password'}
          validation={userValidation.passwordValid}
          onChange={e => onChangeUser('password', e)}
        />
        <div className="action-area">
          <div className="sign-up">
            Haven’t an account? <NavLink to="/sign-up/step-1">Sign Up</NavLink>
          </div>
          <NavLink to="/auth/forgot-password">Forgot Password?</NavLink>
        </div>
        <div
          className={`btn-2 continue-btn ${checkDisableBtn() ? 'disable' : ''}`}
          onClick={doSignIn}>
          Continue
        </div>
      </div>
    </AuthContainer>
  );
}