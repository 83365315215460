import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import ActiveIcon from 'src/Assets/Images/Auth/UpSelling/sat-active-img.svg'
// import InActiveIcon from 'src/Assets/Images/Auth/UpSelling/sat-inActive-img.svg'
import ArrowIcon from 'src/Assets/Images/Auth/arrow.svg'
import RightIcon from 'src/Assets/Images/Auth/UpSelling/thank-you-payment-right-img.svg'
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import {
  getUpSellCondition,
  purchaseUpSell,
} from 'src/Redux/Actions';
import { GetDashboardUrl } from 'src/Constant';
import {
  UpSellingContainer,
  UpSellingItem,
} from 'src/Components';

export default function PaymentThankYouPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const GetLoadingRef = useRef(false);
  const PurchaseLoadingRef = useRef(false);

  const {
    upSellCondition, get_upSellCondition_loading, get_upSellCondition_success,
    upSellPurchaseStatus, upSellPurchase_loading, upSellPurchase_success,
  } = useSelector(
    state => state.UpSell,
  );

  const {
    user, token
  } = useSelector(
    state => state.Auth,
  );

  const [selectedUpSellId, setSelectedUpSellId] = useState('');

  useEffect(() => {
    dispatch(getUpSellCondition({ condition: 1 }));
  }, [dispatch]);

  //Get UpSell Condition Res
  useEffect(() => {
    if (GetLoadingRef.current && !get_upSellCondition_loading) {
      hideSpinner();
      if (get_upSellCondition_success) {
        // console.log("===upSellCondition==", upSellCondition)
      } else {
        showToast("error", 3000, upSellCondition)
      }
    }
    GetLoadingRef.current = get_upSellCondition_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_upSellCondition_loading, get_upSellCondition_success, upSellCondition]);

  //UpSell Purchase res
  useEffect(() => {
    if (PurchaseLoadingRef.current && !upSellPurchase_loading) {
      hideSpinner();
      if (upSellPurchase_success) {
        showToast("success", 3000, upSellPurchaseStatus)
        navigate(`/auth/payment-thank-you-up-selling`);
      } else {
        showToast("error", 3000, upSellPurchaseStatus)
      }
    }
    PurchaseLoadingRef.current = upSellPurchase_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upSellPurchase_loading, upSellPurchase_success, upSellPurchaseStatus, user, token]);

  const onGoToDashboard = () => {
    if (selectedUpSellId) {
      showSpinner();
      upSellPurchase();
    } else {
      const isMims = true;
      window.location.href = GetDashboardUrl(user, token, isMims);
    }
  };

  const upSellPurchase = () => {
    const payload = {
      mims_upsell_id: selectedUpSellId
    }
    dispatch(purchaseUpSell(payload));
  };

  const onChangeUpSell = (id) => {
    setSelectedUpSellId(selectedUpSellId === id ? '' : id);
  };

  return (
    <UpSellingContainer
      title="Thanks for enrolling, but wait a second"
      className='payment-thank-you-page'
    >
      {!_.isEmpty(upSellCondition) && upSellCondition.map((item, index) => (
        <UpSellingItem
          key={index}
          isUpSell={!!(selectedUpSellId === item.id)}
          info={item}
          onChange={onChangeUpSell}
        />
      ))}
      <div className='next-btn-content'>
        <button
          className={`done-btn btn-2`}
          onClick={onGoToDashboard}>
          {`${selectedUpSellId ? "MAKE PAYMENT" : "GO TO DASHBOARD"}`}
        </button>
        <img src={ArrowIcon} alt='arrow-icon' className='arrow-icon show-web' />
      </div>
      <img src={RightIcon} alt='right-icon' className='right-icon show-web' />
    </UpSellingContainer>
  );
}
