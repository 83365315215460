import React, { useState } from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import add from 'src/Assets/Images/Landing/add.svg';

export default function Accordion({
  className, title, content, icon
}) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-component ${className}`}>
      <div className="title-area" onClick={() => toggleAccordion()}>
        <div className="accordion-title">{title}</div>
        <ReactSVG src={icon || add} className={`icon ${isOpen ? icon ? 'has-icon-close' : 'icon-close' : icon ? 'has-icon' : 'icon-open'}`} />
      </div>
      <div
        className={`accordion-content ${isOpen ? 'active' : ''}`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}