import React from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { VideoItem } from 'src/Components/Common';
import { toggleVideoModal, togglePlanModal } from 'src/Redux/Actions';

export default function BelievePractice({
  currentTopic
}) {
  const dispatch = useDispatch();

  const onSignUp = () => dispatch(togglePlanModal(true));
  const onPlayVideo = () => dispatch(toggleVideoModal(true, { videoId: currentTopic?.muse_video }));

  return (
    <div className="believe-practice-component landing-part">
      <div className="container">
        <div className="row believe-practice-content mx-auto">
          <div className="col-lg-5 left-content">
            <VideoItem
              thumbImg={currentTopic?.muse_video_preview}
              onClick={onPlayVideo}
            />
            <div className='video-tag'>
              {`With Taylor as your tutor, you can ${currentTopic?.name}`}
            </div>
          </div>
          <div className="col-lg-7 h-c v-r right-content">
            <h2>{currentTopic?.muse_video_title}</h2>
            <p>{currentTopic?.muse_video_description}</p>
            <div className='btn-2 signup-btn' onClick={onSignUp} >SIGN UP FOR PLAN AND GET THE BONUS</div>
          </div>
        </div>
      </div>
    </div>
  );
}