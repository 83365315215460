import React from 'react';
import './style.scss';
import homeStartImg from 'src/Assets/Images/Landing/Start/landing-header-img.png';

export default function HomeStart({
  currentTopic
}) {

  return (
    <div className="home-start-component">
      <div className="container home-start-container">
        <div className="row">
          <div className="col-lg-6 left-content">
            <div>
              <span>Test Prep</span>
              <span className='opacity'>/</span>
              <span className='opacity'>{currentTopic?.tag}</span>
            </div>
            <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${currentTopic?.logo}`} alt='logo' className='small-logo' />
            <h1>{currentTopic?.name}</h1>
            {/* <p>{`${currentTopic?.questions_count} ${currentTopic?.name} Questions With Video Explanation \nDesign To Make You An Expert.`}</p> */}
            <p>{currentTopic?.tag_line}</p>
          </div>
          <div className="col-lg-6 show-web right-content">
            <img src={homeStartImg} alt="home-img" className="home-img" />
          </div>
        </div>
      </div>
    </div>
  );
}