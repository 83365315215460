import {
  GET_MIMS_REVIEWS, GET_MIMS_REVIEWS_SUCCESS, GET_MIMS_REVIEWS_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  getMimsReviews as getMimsReviewsApi,
} from 'src/Api';

//MIMS Testimonial Reviews
function* getMimsReviews(payload) {
  try {
    const result = yield getMimsReviewsApi(payload.data)
    if (result && result.data) {
      yield put({ type: GET_MIMS_REVIEWS_SUCCESS, data: result.data })
    } else {
      yield put({ type: GET_MIMS_REVIEWS_FAIL, data: [] })
    }
  } catch (err) {
    yield put({ type: GET_MIMS_REVIEWS_FAIL, data: [] })
  }
}

export function* watchGetMimsReviews() {
  yield takeLatest(GET_MIMS_REVIEWS, getMimsReviews)
}