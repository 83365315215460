import { Api } from './Api';

export function* getUpSellCondition(data) {
  const jsonData = yield Api.get(`/mimsupsells?condition=${data.condition}`);
  return jsonData;
}

export function* purchaseUpSell(data) {
  const jsonData = yield Api.post("/mimsupsells", data);
  return jsonData;
}

