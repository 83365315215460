import {
  TOGGLE_REVIEW_MODAL,
  TOGGLE_VIDEO_MODAL,
  TOGGLE_PRACTICE_MODAL,
  TOGGLE_PLAN_MODAL,
  TOGGLE_QUESTION_MODAL,
  TOGGLE_NOTE_MODAL,
} from '../Type';

export const toggleReviewModal = (isReviewModal, reviewInfo) => {
  return {
    type: TOGGLE_REVIEW_MODAL,
    data: { isReviewModal, reviewInfo }
  }
}

export const toggleVideoModal = (isVideoModal, videoInfo) => {
  return {
    type: TOGGLE_VIDEO_MODAL,
    data: { isVideoModal, videoInfo }
  }
}

export const togglePracticeModal = (isPracticeModal) => {
  return {
    type: TOGGLE_PRACTICE_MODAL,
    data: { isPracticeModal }
  }
}

export const togglePlanModal = (isPlanModal) => {
  return {
    type: TOGGLE_PLAN_MODAL,
    data: { isPlanModal }
  }
}

export const toggleQuestionModal = (isOpen) => {
  return {
    type: TOGGLE_QUESTION_MODAL,
    data: { isOpen }
  }
}

export const toggleNoteModal = (isNoteModal, note = "") => {
  return {
    type: TOGGLE_NOTE_MODAL,
    data: { isNoteModal, note }
  }
}