import {
  GET_BRAINTREE_KEY, GET_BRAINTREE_KEY_SUCCESS, GET_BRAINTREE_KEY_FAIL,
  NONCE_UPDATE, NONCE_UPDATE_SUCCESS, NONCE_UPDATE_FAIL,
  UPDATE_PURCHASE, UPDATE_PURCHASE_SUCCESS, UPDATE_PURCHASE_FAIL,
  GET_DISCOUNT, GET_DISCOUNT_SUCCESS, GET_DISCOUNT_FAIL,
} from '../Type';

const INITIAL = {
  braintreeKey: '',
  get_braintreeKey_loading: false,
  get_braintreeKey_success: false,

  nonceUpdateStatus: '',
  nonceUpdateStatus_loading: false,
  nonceUpdateStatus_success: false,

  purchaseStatus: '',
  purchase_loading: false,
  purchase_success: false,

  get_discount_status: '',
  total_price: '',
  get_discount_loading: false,
  get_discount_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get BrainTree Key
    */
    case GET_BRAINTREE_KEY: {
      return {
        ...state,
        get_braintreeKey_loading: true,
        get_braintreeKey_success: false,
      }
    }
    case GET_BRAINTREE_KEY_FAIL: {
      return {
        ...state,
        braintreeKey: '',
        get_braintreeKey_loading: false,
        get_braintreeKey_success: false,
      }
    }
    case GET_BRAINTREE_KEY_SUCCESS: {
      return {
        ...state,
        braintreeKey: action.data.token,
        get_braintreeKey_loading: false,
        get_braintreeKey_success: true,
      }
    }

    /**
     * Nonce Update
    */
    case NONCE_UPDATE: {
      return {
        ...state,
        nonceUpdateStatus_loading: true,
        nonceUpdateStatus_success: false,
      }
    }
    case NONCE_UPDATE_FAIL: {
      return {
        ...state,
        nonceUpdateStatus: action.data.message,
        nonceUpdateStatus_loading: false,
        nonceUpdateStatus_success: false,
      }
    }
    case NONCE_UPDATE_SUCCESS: {
      return {
        ...state,
        nonceUpdateStatus: action.data.message,
        nonceUpdateStatus_loading: false,
        nonceUpdateStatus_success: true,
      }
    }

    /**
     * Update Purchase
    */
    case UPDATE_PURCHASE: {
      return {
        ...state,
        purchase_loading: true,
        purchase_success: false,
      }
    }
    case UPDATE_PURCHASE_FAIL: {
      return {
        ...state,
        purchaseStatus: action.data.error,
        purchase_loading: false,
        purchase_success: false,
      }
    }
    case UPDATE_PURCHASE_SUCCESS: {
      return {
        ...state,
        purchaseStatus: action.data.message,
        purchase_loading: false,
        purchase_success: true,
      }
    }

    /**
     * Get discount
    */
    case GET_DISCOUNT: {
      return {
        ...state,
        get_discount_status: '',
        total_price: '',
        get_discount_loading: true,
        get_discount_success: false,
      }
    }
    case GET_DISCOUNT_FAIL: {
      return {
        ...state,
        get_discount_status: action.data.message,
        total_price: '',
        get_discount_loading: false,
        get_discount_success: false,
      }
    }
    case GET_DISCOUNT_SUCCESS: {
      return {
        ...state,
        get_discount_status: action.data.message,
        total_price: action.data.price,
        get_discount_loading: false,
        get_discount_success: true,
      }
    }

    default:
      return state;
  }
}