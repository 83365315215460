import {
  GET_PLAN,
} from '../Type';

export const getPlan = (data) => {
  return {
    type: GET_PLAN,
    data
  }
}
