import { Api } from './Api';

export function* getQuestions(topic_id) {
  const jsonData = yield Api.get(`/mimstopics/${topic_id}/questions`);
  return jsonData;
}

export function* getTestId(data) {
  const jsonData = yield Api.post(`/mimstests`, data);
  return jsonData;
}

export function* getScores(test_id) {
  const jsonData = yield Api.get(`/mimstests?test_id=${test_id}`);
  return jsonData;
}

export function* submitAnswer(data) {
  const jsonData = yield Api.post(`/mimsanswers`, data);
  return jsonData;
}
