import {
  GET_TOPIC_LIST, GET_TOPIC_LIST_FAIL, GET_TOPIC_LIST_SUCCESS,
  SET_CURRENT_TOPIC
} from '../Type';

const INITIAL = {
  topicList: [],
  get_topic_list_success: false,

  currentTopic: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get Landing Topics
     */
    case GET_TOPIC_LIST: {
      return {
        ...state,
        topicList: [],
        get_topic_list_success: false,
      }
    }
    case GET_TOPIC_LIST_FAIL: {
      return {
        ...state,
        topicList: [],
        get_topic_list_success: false,
      }
    }
    case GET_TOPIC_LIST_SUCCESS: {
      let topicList = action.data || [];
      return {
        ...state,
        topicList: topicList,
        get_topic_list_success: true,
      }
    }

    /**
     * Set Current Topic
     */
    case SET_CURRENT_TOPIC: {
      const { currentTopic } = action.data;

      return {
        ...state,
        currentTopic
      }
    }

    default:
      return state;
  }
}