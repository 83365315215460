import React from 'react';
import './style.scss';

export default function TopMenu() {

  const disableTopMenu = !(window.location.pathname?.split('/')[1] === '');

  return (
    <div className={`top-menu-component show-web ${disableTopMenu ? 'display-none' : ''}`}>
      <div className="top-menu v-c">
        <a href="https://gradegetter.com/" className="tag" target="_blank" rel="noopener noreferrer">GradeGetter</a>
        <div className='dot'>•</div>
        <a href="/" className="tag make-it-sense" rel="noopener noreferrer">Make It Make Sense</a>
        <div className='dot'>•</div>
        <a href="https://gradegetter.com/gradeTv/" className="tag" target="_blank" rel="noopener noreferrer">Grade TV</a>
        <div className='dot'>•</div>
        <a href="https://icodeedu.com/" className="tag" target="_blank" rel="noopener noreferrer">iCodeEdu</a>
        <div className='dot'>•</div>
        <a href="https://gradegetter.com/" className="tag" target="_blank" rel="noopener noreferrer">AskFranky</a>
      </div>
    </div>
  );
}