import {
  GET_UPSELL_CONDITION,
  PURCHASE_UPSELL
} from '../Type';


export const getUpSellCondition = (data) => {
  return {
    type: GET_UPSELL_CONDITION,
    data
  }
}

export const purchaseUpSell = (data) => {
  return {
    type: PURCHASE_UPSELL,
    data
  }
}
