import React from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import KeyWeb from 'src/Assets/Images/Landing/GainLifetime/key-web.svg';
import KeyMobile from 'src/Assets/Images/Landing/GainLifetime/key-mobile.svg';
import StarIcon from 'src/Assets/Images/Landing/GainLifetime/star.svg';
import { togglePlanModal } from 'src/Redux/Actions';

export default function GainLifetime() {

  const dispatch = useDispatch();

  const onAccessPlan = () => dispatch(togglePlanModal(true));

  return (
    <div className="gain-life-time-component landing-part">
      <div className="container">
        <div className="row gain-life-time-content">
          <div className="col-lg-7 left-content">
            <h1>30+ Hours of Live Review Sessions <strong>(Worth $1500)</strong> every year - <strong>forever.</strong></h1>
            {/* <h2>of Yearly Live Review Tutoring Sessions - <strong>Save $1500</strong>Per Year!</h2> */}
            <div className='gain-content'>
              <div className='detail-list'>
                <div className='detail-item v-c'>
                  <img src={StarIcon} alt='star-icon' className='star-icon' />
                  <p>Lifetime VIP Pass to attend Live SAT Math Prep for 30+ hours per year</p>
                </div>
                <div className='detail-item'>
                  <img src={StarIcon} alt='star-icon' className='star-icon' />
                  <p>Review Sessions are offered for 2 weeks before every SAT</p>
                </div>
                <div className='detail-item'>
                  <img src={StarIcon} alt='star-icon' className='star-icon' />
                  <p>Get your burning questions answered by our professional SAT tutors</p>
                </div>
              </div>
              <div className='give-away-section'>
                This is a huge <strong>GIVEAWAY</strong>, and our goal is to make sure every student has access to top-quality SAT Prep which
                is usually reserved for the elites.
              </div>
              {/* <div className='access-section'>
                Each year students will get access to up to 30 hours of live review sessions with an elite SAT Prep Tutor.
              </div>
              <h2>This is valued at over <strong>$1500</strong>/YR, but students will get it <strong>for FREE</strong>/YR.</h2> */}
            </div>
            <div className='btn-2 upgrade-btn' onClick={onAccessPlan} >YES! UPGRADE ME TO THE LIFETIME ACCESS PLAN</div>
          </div>
          <div className="col-lg-5 center right-content">
            <img src={KeyWeb} alt='e-book' className='key-img show-web' />
            <img src={KeyMobile} alt='e-book' className='key-img show-mobile' />
          </div>
        </div>
      </div>
    </div>
  );
}