import { all, fork } from 'redux-saga/effects';
import { watchGetTopicList } from './Topic';
import { watchPracticeNotify } from './Notify';
import { watchGetAllReviews } from './Review';
import { watchGetMimsReviews } from './ReviewT';
import {
  watchSignIn,
  watchForgotPassword,
  watchStudentSignUP,
} from './Auth';
import {
  watchGetBraintreeKey,
  watchNonceUpdate,
  watchUpdatePurchase,
  watchGetDiscount,
} from './Payment';
import {
  watchGetUpSellCondition,
  watchPurchaseUpSell,
} from './UpSell';
import { watchGetSubjects } from './Subject';

import { watchMailChimpAction } from './MailChimp';
import { watchFrogUpHubSpot } from './HubSpot';
import {
  watchGetQuestions,
  watchGetTestId,
  watchGetScores,
  watchSubmitAnswer
} from './QA';
import {
  watchGetPlan
} from './Plan';
import {
  watchGetFaq
} from './Other';

export default function* rootSaga() {
  yield all([
    fork(watchGetTopicList),
    fork(watchPracticeNotify),

    fork(watchGetAllReviews),
    fork(watchGetMimsReviews),

    fork(watchSignIn),
    fork(watchForgotPassword),
    fork(watchStudentSignUP),
    fork(watchGetBraintreeKey),
    fork(watchNonceUpdate),
    fork(watchUpdatePurchase),
    fork(watchGetDiscount),

    fork(watchGetUpSellCondition),
    fork(watchPurchaseUpSell),

    fork(watchGetSubjects),

    fork(watchMailChimpAction),
    fork(watchFrogUpHubSpot),

    fork(watchGetQuestions),
    fork(watchGetTestId),
    fork(watchGetScores),
    fork(watchSubmitAnswer),

    fork(watchGetPlan),
    fork(watchGetFaq),

  ]);
}
