import React from 'react';
import './style.scss';
import Rating from '../Rating';

function innerFunc(description) {
  return { __html: description }
};

export default function Review({
  info, className = ''
}) {

  const {
    rating,
    title,
    review,
    user: { name, picture, region },
  } = info

  return (
    <div className={`review-component v-r round-object ${className}`}>
      <div className="review-header">
        <Rating
          rating={Number(rating)}
        />
        <div className='title'>{title}</div>
      </div>
      <p className="review" dangerouslySetInnerHTML={innerFunc(review)} />
      <div className="review-footer v-c">
        <div className='avatar-content center'>
          <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${picture}`} className='avatar' alt='avatar' />
        </div>
        <div className='name-content'>
          <div className='name'>{name}</div>
          <span className='address'>{region}</span>
        </div>
      </div>
    </div>
  );
}