import {
  GET_FAQ, GET_FAQ_SUCCESS, GET_FAQ_FAIL,
  SET_REFERRAL_CODE,
  SET_QUESTION_STEP,
} from '../Type';

const INITIAL = {
  get_faq_loading: false,
  get_faq_success: false,
  faqs: [],

  referral_code: '',

  questionStep: 0
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get FAQ
     */
    case GET_FAQ: {
      return {
        ...state,
        get_faq_loading: true,
        get_faq_success: false,
      }
    }
    case GET_FAQ_FAIL: {
      return {
        ...state,
        faqs: [],
        get_faq_loading: false,
        get_faq_success: false,
      }
    }
    case GET_FAQ_SUCCESS: {
      return {
        ...state,
        faqs: action.data,
        get_faq_loading: false,
        get_faq_success: true,
      }
    }

    /**
     * Set Referral Code
     */
    case SET_REFERRAL_CODE: {
      const { referral_code } = action.data;
      return {
        ...state,
        referral_code,
      };
    }

    /**
     * Set Question Step
     */
    case SET_QUESTION_STEP: {
      const { questionStep } = action.data;
      return {
        ...state,
        questionStep,
      };
    }

    default:
      return state;
  }
}