import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CardNumberValidation,
  CardExpDateValidation,
  CvcValidation,
  ZipCodeValidation
} from 'src/Utils/Validation';
import {
  expDateFormat,
} from 'src/Utils/Format';
import ActiveUpSellImg from 'src/Assets/Images/UpSelling/active-up-selling-img.svg'
import InActiveUpSellImg from 'src/Assets/Images/UpSelling/inActive-up-selling-img.svg'
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import { FormInput, SignupContainer, } from 'src/Components';
import {
  getBraintreeKey,
  nonceUpdate,
  updatePurchase,
  getPlan,
  getDiscount,
  frogUpHubSpot,
} from 'src/Redux/Actions';
import { braintainService } from 'src/Utils/PaymentServices';
import { UpSellingItem } from 'src/Components';

export default function PaymentPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { topic_id, plan_id, share_friend, discount_code } = useParams();

  const share_friend_num = Number(share_friend)

  const getLoadingRef = useRef(false);
  const NonceLoadingRef = useRef(false);
  const PurchaseLoadingRef = useRef(false);
  const HubSpotLoadingRef = useRef(false);
  const discountLoadingRef = useRef(false);

  const {
    braintreeKey, get_braintreeKey_success, get_braintreeKey_loading,
    nonceUpdateStatus, nonceUpdateStatus_success, nonceUpdateStatus_loading,
    purchaseStatus, purchase_success, purchase_loading,
    total_price, get_discount_status, get_discount_success, get_discount_loading
  } = useSelector(
    state => state.Payment,
  );

  const {
    frogHubSpotRes, frogHubSpot_success, frogHubSpot_loading,
  } = useSelector(
    state => state.HubSpot,
  );

  const {
    plans
  } = useSelector((state) => state.Plan);

  const {
    topicList
  } = useSelector((state) => state.Topic);

  const {
    user, token
  } = useSelector(
    state => state.Auth,
  );

  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    expDate: '',
    cvc: '',
    zipCode: '',
  });

  const [discount, setDiscount] = useState('');

  const [cardInfoValidation, setCardInfoValidation] = useState({
    cardNumberValid: null,
    expDateValid: null,
    cvcValid: null,
    zipCodeValid: null,
  });
  const [isPaymentUpSell, setIsPaymentUpSell] = useState(false);

  useEffect(() => {
    if (_.isEmpty(plans)) {
      dispatch(getPlan({ topic_id }));
    }
  }, [dispatch, plans, topic_id,]);

  useEffect(() => {
    getTotalPrice()
    // eslint-disable-next-line no-use-before-define
  }, [dispatch, share_friend_num, isPaymentUpSell]);

  //Get BraintreeKey Res
  useEffect(() => {
    if (getLoadingRef.current && !get_braintreeKey_loading) {
      if (get_braintreeKey_success) {
        braintainService(braintreeKey, cardInfo).then((res) => {
          sendNonce(res);
        })
          .catch(err => {
            hideSpinner();
            showToast("error", 3000, "Invalid Card Info")
          });
      } else {
        hideSpinner();
      };
    }
    getLoadingRef.current = get_braintreeKey_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [braintreeKey, get_braintreeKey_loading, get_braintreeKey_success]);

  //Update Nonce Res
  useEffect(() => {
    if (NonceLoadingRef.current && !nonceUpdateStatus_loading) {
      if (nonceUpdateStatus_success) {
        showToast("success", 3000, nonceUpdateStatus)
        purchase();
      } else {
        hideSpinner();
        showToast("error", 3000, nonceUpdateStatus)
      }
    }
    NonceLoadingRef.current = nonceUpdateStatus_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonceUpdateStatus_loading, nonceUpdateStatus_success, nonceUpdateStatus]);

  //Purchase res
  useEffect(() => {
    if (PurchaseLoadingRef.current && !purchase_loading) {
      if (purchase_success) {
        showToast("success", 3000, purchaseStatus)
        callFrogHubSpot();
      } else {
        hideSpinner();
        showToast("error", 3000, purchaseStatus)
      }
    }
    PurchaseLoadingRef.current = purchase_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase_loading, purchase_success, purchaseStatus, user, token]);

  //HubSpot res
  useEffect(() => {
    if (HubSpotLoadingRef.current && !frogHubSpot_loading) {
      hideSpinner();
      navigate(`/auth/payment-thank-you`);
    }
    HubSpotLoadingRef.current = frogHubSpot_loading;
  }, [frogHubSpot_loading, frogHubSpot_success, frogHubSpotRes, navigate]);

  //Get Discount res
  useEffect(() => {
    if (discountLoadingRef.current && !get_discount_loading) {
      hideSpinner();
      if (get_discount_success) {
        if (share_friend_num !== 0 || discount_code !== 'undefined') {
          setDiscount(get_discount_status);
        }
      }
    }
    discountLoadingRef.current = get_discount_loading;
  }, [dispatch, get_discount_loading, get_discount_success, get_discount_status, share_friend_num, discount_code]);

  const sendNonce = (nonce) => {
    const formData = new FormData()
    formData.append('todo', 'new');
    formData.append('nonce', nonce);
    dispatch(nonceUpdate(formData));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getTotalPrice = () => {
    const payload = {
      plan_id: plan_id,
      discount: discount_code !== 'undefined' ? discount_code : '',
      multiple_login: share_friend_num,
      upsell_book: isPaymentUpSell ? "book" : "",
    }
    showSpinner();
    dispatch(getDiscount(payload));
  }

  const purchase = () => {
    const payload = {
      plan_id: Number(plan_id),
      topic_id: Number(topic_id),
      discount: discount_code !== 'undefined' ? discount_code : '',
      multiple_login: share_friend_num,
      upsell_book: isPaymentUpSell ? "book" : ""
    }
    dispatch(updatePurchase(payload));
  };

  const callFrogHubSpot = () => {
    const planName = plans.find(item => item.id === Number(plan_id))?.name || '';
    const topicName = topicList.find(item => item.id === Number(topic_id))?.name || '';
    const payload = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      product: topicName,
      plan: planName
    };
    dispatch(frogUpHubSpot(payload));
  }

  const onChangeCardInfo = (field, validationField, value) => {
    if (field === 'expDate') {
      setCardInfo({
        ...cardInfo,
        [field]: expDateFormat(value)
      });
    } else {
      setCardInfo({
        ...cardInfo,
        [field]: value,
      });
    }

    setCardInfoValidation({
      ...cardInfoValidation,
      [validationField]: getValidation(field, value)
    });
  };

  const getValidation = (field, value) => {
    if (field === 'cardNumber')
      return CardNumberValidation(value)
    if (field === 'expDate')
      return CardExpDateValidation(value)
    if (field === 'cvc')
      return CvcValidation(value)
    return ZipCodeValidation(value)
  };

  const checkDisableBtn = () => {
    if (
      CardNumberValidation(cardInfo.cardNumber) &&
      CardExpDateValidation(cardInfo.expDate) &&
      CvcValidation(cardInfo.cvc) &&
      ZipCodeValidation(cardInfo.zipCode) &&
      getPrice() > 0
    )
      return false;
    return true;
  };

  const onPayment = () => {
    showSpinner();
    dispatch(getBraintreeKey());
  };

  const onGoToDashboard = () => {
    showSpinner();
    purchase();
  };

  const getPrice = () => {
    let totalUpSell = 0;
    if (isPaymentUpSell) {
      totalUpSell += 29.99
    };

    if (share_friend_num === 3) {
      totalUpSell += 9.99
    }

    if (share_friend_num === 5) {
      totalUpSell += 14.99
    }

    const currentPlanIndex = plans.findIndex(temp => temp.id === Number(plan_id));

    const currentPlanPrice = currentPlanIndex > -1 ? plans[currentPlanIndex]?.price?.split('$')[1] : 0;
    if (plan_id === "1" || plan_id === "2") {
      return (Number(currentPlanPrice) + totalUpSell)
    }
    if (plan_id === "3") {
      return (49.99 + totalUpSell)
    }
    return totalUpSell;
  };

  const onChangeUpSell = () => setIsPaymentUpSell(!isPaymentUpSell);

  return (
    <SignupContainer
      title="Payment"
      description="30 Days Money Back Guarantee"
      className='signup-payment'
      plan_id={plan_id}
      topic_id={topic_id}
      discount={discount}
    >
      <div className='amount-header v-c'>
        <div className='sub-title'>Payment Amount</div>
        <h3 className='ml-2'>{`$${total_price}`}</h3>
      </div>
      <div className='col-lg-12' >
        <FormInput
          placeholder={"Card number"}
          type={'number'}
          validation={cardInfoValidation.cardNumberValid}
          value={cardInfo.cardNumber}
          onChange={e => onChangeCardInfo('cardNumber', 'cardNumberValid', e)}
        />
      </div>
      <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <FormInput
            placeholder={"Expire date"}
            className="exp-date"
            validation={cardInfoValidation.expDateValid}
            value={cardInfo.expDate}
            onChange={e => onChangeCardInfo('expDate', 'expDateValid', e)}
          />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <FormInput
            placeholder={"CVS"}
            type={'number'}
            validation={cardInfoValidation.cvcValid}
            value={cardInfo.cvc}
            className="last-name"
            onChange={e => onChangeCardInfo('cvc', 'cvcValid', e)}
          />
        </div>
      </div>
      <div className='col-lg-12' >
        <FormInput
          placeholder={"Zip code"}
          type={'number'}
          validation={cardInfoValidation.zipCodeValid}
          value={cardInfo.zipCode}
          onChange={e => onChangeCardInfo('zipCode', 'zipCodeValid', e)}
        />
      </div>
      <UpSellingItem
        isUpSell={isPaymentUpSell}
        info={{
          id: 0,
          name: 'Add our SAT Vocab eCheatsheet',
          description: 'with 500+ must-know SAT vocabulary words to achieve a perfect score',
          picture: isPaymentUpSell ? ActiveUpSellImg : InActiveUpSellImg,
          original_price: '29.99',
          current_price: '29.99',
        }
        }
        onChange={onChangeUpSell}
      />
      <button
        className={`done-btn btn-2 ${checkDisableBtn() ? 'disable' : ''}`}
        disabled={checkDisableBtn()}
        onClick={onPayment}>
        MAKE PAYMENT
      </button>
      <div className='policy-content'>
        <p className='policy-text'>
          We assure you a full refund if the request is made within 30 days of your purchase. We commit to processing refunds within 24 hours following your request. For any inquiries or requests, contact us at
          <a
            className='email-link'
            href={`mailto:info@gradegetter.com`}>
            {`info@gradegetter.com`}
          </a>
          or visit our office at 1751 River Run Suite 200, Fort Worth, TX 76107.
        </p>
      </div>
      <button
        className={`done-btn btn-2 mt-5 ${getPrice() > 0 ? 'disable' : ''}`}
        disabled={!!(getPrice() > 0)}
        onClick={onGoToDashboard}>
        GO TO DASHBOARD
      </button>
    </SignupContainer>
  );
}
