const API_URL = process.env.REACT_APP_API_URL

class API {
  get = function* (url) {
    const token = sessionStorage.getItem('token') || '';
    //upsell test token const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmdyYWRlZ2V0dGVyLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY4NTUyNzQyMiwibmJmIjoxNjg1NTI3NDIyLCJqdGkiOiJsSVNyazdyVENJMlJkbUU0Iiwic3ViIjoyODA4LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.hdBEFc8-I_C1sSE2WqvdbL0E-b--i2ugh2cMt8MuRQ8'
    let jsonData = yield fetch(API_URL + url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .catch(err => console.log("API BUG", err));
    return jsonData;
  }

  post = function* postApi(url, data) {
    const token = sessionStorage.getItem('token') || '';
    //upsell test token const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmdyYWRlZ2V0dGVyLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY4NTUyNzQyMiwibmJmIjoxNjg1NTI3NDIyLCJqdGkiOiJsSVNyazdyVENJMlJkbUU0Iiwic3ViIjoyODA4LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.hdBEFc8-I_C1sSE2WqvdbL0E-b--i2ugh2cMt8MuRQ8'
    let jsonData = yield fetch(API_URL + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .catch(err => console.log("API BUG", err));
    return jsonData;
  }

  formPost = function* (url, data) {
    const token = sessionStorage.getItem('token') || '';
    let jsonData = yield fetch(API_URL + url, {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }).then(res => res.json())
      .catch(err => console.log("API BUG", err));
    return jsonData;
  }
}

export const Api = new API();
