import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import { PracticeItem } from 'src/Components';
import {
  togglePracticeModal,
  SetCurrentTopic,
} from 'src/Redux/Actions';

export default function LandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    topicList
  } = useSelector(
    state => state.Topic,
  );

  const onSelectPractice = (item) => {
    dispatch(SetCurrentTopic(item));
    if (item.status === 'active') {
      navigate(`/make-it-make-sense/topic_id/${item.id}`);
    } else {
      dispatch(togglePracticeModal(true));
    };
  };

  return (
    <div className="landing-page">
      <div className="container">
        <h1 className='text-center'>Select Your Test Prep and Practice</h1>
        <div className="description text-center">Elite Test Prep Packed With endless questions and tutor-led solutions. Say goodbye to scrambling online for help</div>
        <div className="landing-content">
          {!_.isEmpty(topicList) && topicList.map((item, index) =>
            <PracticeItem
              key={index}
              info={item}
              onClick={onSelectPractice}
            />
          )}
        </div>
      </div>
    </div>
  );
}
