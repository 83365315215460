import {
  GET_UPSELL_CONDITION, GET_UPSELL_CONDITION_SUCCESS, GET_UPSELL_CONDITION_FAIL,
  PURCHASE_UPSELL, PURCHASE_UPSELL_SUCCESS, PURCHASE_UPSELL_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  getUpSellCondition as getUpSellConditionApi,
  purchaseUpSell as purchaseUpSellApi,
} from 'src/Api';

/**
 * Get UpSell Condition
*/
function* getUpSellCondition(payload) {
  try {
    const result = yield getUpSellConditionApi(payload.data);
    if (result && result.data) {
      yield put({ type: GET_UPSELL_CONDITION_SUCCESS, data: result });
    } else {
      yield put({ type: GET_UPSELL_CONDITION_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetUpSellCondition() {
  yield takeLatest(GET_UPSELL_CONDITION, getUpSellCondition);
}

/**
 * Purchase UpSell
*/
function* purchaseUpSell(payload) {
  try {
    const result = yield purchaseUpSellApi(payload.data);
    if (result && result.data.error === false) {
      yield put({ type: PURCHASE_UPSELL_SUCCESS, data: result.data });
    } else {
      yield put({ type: PURCHASE_UPSELL_FAIL, data: result.data });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchPurchaseUpSell() {
  yield takeLatest(PURCHASE_UPSELL, purchaseUpSell);
}

