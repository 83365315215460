import {
  GET_TOPIC_LIST,
  SET_CURRENT_TOPIC,
} from '../Type';

export const getTopicList = () => {
  return {
    type: GET_TOPIC_LIST,
    data: {}
  }
}

export const SetCurrentTopic = (currentTopic) => {
  return {
    type: SET_CURRENT_TOPIC,
    data: { currentTopic }
  }
}