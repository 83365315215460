import React from 'react';
import './style.scss';
import moment from "moment";
import { useDispatch } from 'react-redux';
import DecideImg from 'src/Assets/Images/Landing/DecideNow/decide.svg';
import DecideMobileImg from 'src/Assets/Images/Landing/DecideNow/decide-mobile.svg';
import Number1 from 'src/Assets/Images/Landing/DecideNow/number-1.svg';
import Number2 from 'src/Assets/Images/Landing/DecideNow/number-2.svg';
import Number3 from 'src/Assets/Images/Landing/DecideNow/number-3.svg';
// import Number4 from 'src/Assets/Images/Landing/DecideNow/number-4.svg';
// import Number5 from 'src/Assets/Images/Landing/DecideNow/number-5.svg';
import { togglePlanModal } from 'src/Redux/Actions';

export default function DecideNow() {
  const dispatch = useDispatch();

  const onUpgrade = () => dispatch(togglePlanModal(true));

  const getDate = () => {
    const date = new Date(Date.now() + 12096e5)
    return moment(date).format("MMM Do");
  };

  return (
    <div className="decide-now-component landing-part">
      <div className="container">
        <div className="row decide-now-content">
          <div className="col-lg-7 left-content">
            <div className='decide-content'>
              <h1>{`Don’t wait until it’s too late`}</h1>
              <div className='detail-list'>
                <div className='detail-item v-c'>
                  <img src={Number1} alt='star-icon' className='star-icon' />
                  <p>{`This SAT prep is only available at this steep discount until ${getDate()}`}</p>
                </div>
                <div className='detail-item'>
                  <img src={Number2} alt='star-icon' className='star-icon' />
                  <p>We are shifting our Lifetime Access course to “One-Year” Access soon, but if you purchase now, you will get access FOREVER</p>
                </div>
                <div className='detail-item'>
                  <img src={Number3} alt='star-icon' className='star-icon' />
                  <p>We limit the # of students in our virtual review sessions to ensure consistent, smooth video streaming. This allows us to offer disruption-free review sessions, but it also means you need to sign up NOW to make sure you get access to our sessions.</p>
                </div>
                <div className='detail-item'>
                  <p><strong>BONUS:</strong> We offer a 30-day money-back guarantee, so in the unlikely scenario that aren’t 100% satisfied with our course, we will gladly give you a full refund. </p>
                </div>
                {/* <div className='detail-item'>
                  <img src={Number4} alt='star-icon' className='star-icon' />
                  <p>That's right... even though the 30 hours of live review class is virtual, we are limiting the number of students who can enroll. That way, we can maintain a great Internet bandwidth without any unforeseen disruption while on the Live SAT Prep Sessions with members with lifetime access to Make The SAT Math Make Sense.</p>
                </div>
                <div className='detail-item'>
                  <img src={Number5} alt='star-icon' className='star-icon' />
                  <p>Additionally, when we keep the group small, we're able to give you more intimate and in-depth tutoring, unlike anything you've experienced before!</p>
                </div> */}
              </div>
              <div className='act-now-section'>
                But you need to <strong>ACT NOW</strong> and before you leave this page. Once you do leave this page, this offer disappears *POOF*
              </div>
              <h3>So… why are you still reading? Click the button below to claim the <strong>best offer</strong> we will ever promote!</h3>
            </div>
            <div className='btn-2 upgrade-btn' onClick={onUpgrade} >
              YES! UPGRADE ME TO THE LIFETIME ACCESS PLAN
            </div>
          </div>
          <div className="col-lg-5 right-content show-web">
            <img src={DecideImg} alt='decide-right-img' className='decide-right-img show-web' />
            <img src={DecideMobileImg} alt='decide-right-img' className='decide-right-img show-mobile' />
          </div>
        </div>
      </div>
    </div>
  );
}