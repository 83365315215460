import { Api } from './Api';

export function* getBraintreeKey() {
  const jsonData = yield Api.get("/payments/braintreekey");
  return jsonData;
}

export function* nonceUpdate(data) {
  const jsonData = yield Api.formPost("/payments/braintreecard", data);
  return jsonData;
}

export function* updatePurchase(data) {
  const jsonData = yield Api.post("/mimsrateplans", data);
  return jsonData;
}

export function* getDiscount(data) {
  const jsonData = yield Api.get(`/mimsrateplans/discount?plan_id=${data.plan_id}&discount=${data.discount}&multiple_login=${data.multiple_login}&upsell_book=${data.upsell_book}`);
  return jsonData;
}
