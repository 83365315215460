import { Routes, Route } from 'react-router-dom';
import { AuthHeader } from 'src/Components';
import {
  SignInPage,
  ForgotPassPage,
  NewPassPage,
  SignUpPage,
  PaymentPage,
  PaymentThankYouPage,
  PaymentThankYouUpSellPage
} from 'src/Pages';


function AuthRoutes() {
  return (
    <div>
      <AuthHeader />
      <div className='main-content'>
        <Routes>
          <Route exact path="/sign-in" element={<SignInPage />} />
          <Route exact path="/forgot-password" element={<ForgotPassPage />} />
          <Route exact path="/new-password" element={<NewPassPage />} />
          <Route exact path="/sign-up/topic_id/:topic_id/plan_id/:plan_id" element={<SignUpPage />} />
          <Route exact path="/payment/topic_id/:topic_id/plan_id/:plan_id/discount/:discount_code/share_friend/:share_friend" element={<PaymentPage />} />
          <Route exact path="/payment-thank-you" element={<PaymentThankYouPage />} />
          <Route exact path="/payment-thank-you-up-selling" element={<PaymentThankYouUpSellPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default AuthRoutes;
