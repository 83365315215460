import {
  PRACTICE_NOTIFY, PRACTICE_NOTIFY_SUCCESS, PRACTICE_NOTIFY_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  practiceNotify as practiceNotifyApi,
} from 'src/Api';
/**
 * Practice Notify
 */
function* practiceNotify(payload) {
  try {
    const result = yield practiceNotifyApi(payload.data);
    if (result && result.message) {
      yield put({ type: PRACTICE_NOTIFY_SUCCESS, data: result });
    } else {
      yield put({ type: PRACTICE_NOTIFY_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchPracticeNotify() {
  yield takeLatest(PRACTICE_NOTIFY, practiceNotify);
}
