import React, { useEffect, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Scores, QuestionAnswerFlow, } from 'src/Components';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';
import {
  getQuestions,
  getTestId,
  // getScores,
} from 'src/Redux/Actions';

export default function QuestionAnswer({
  isLanding = false,
  topic_id
}) {
  const dispatch = useDispatch();
  const getLoadingRef = useRef(false);
  const getTestIdRef = useRef(false);

  const {
    questions, getQuestions_success, getQuestions_loading,
    testId, get_testId_success, get_testId_loading,
    scores,
  } = useSelector(
    state => state.QA,
  );

  useEffect(() => {
    showSpinner();
    dispatch(getQuestions(topic_id));
    dispatch(getTestId({ topic_id }))
  }, [dispatch, topic_id]);

  //Get Questions res
  useEffect(() => {
    if (getLoadingRef.current && !getQuestions_loading) {
      hideSpinner();
      if (getQuestions_success) {
        // console.log("===questions===", questions)
      }
    }
    getLoadingRef.current = getQuestions_loading;
  }, [getQuestions_loading, getQuestions_success, questions]);

  //Get Test id res
  useEffect(() => {
    if (getTestIdRef.current && !get_testId_loading) {
      hideSpinner();
      if (get_testId_success) {
        // console.log("===testId===", testId)
        onGetAnswerScours();
      }
    }
    getTestIdRef.current = get_testId_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_testId_loading, get_testId_success, testId]);

  const onGetAnswerScours = () => {
    // dispatch(getScores(testId));
  }

  return (
    <div className="question-answer-component landing-part">
      <div className="container">
        {/* <h3>Ending Questions</h3> */}
        <div className="row">
          <div className="col-lg-4 left-content">
            <div className='sub-header-content' />
            <Scores
              scores={scores}
              testId={testId}
            />
          </div>
          <div className="col-lg-8 right-content">
            {!_.isEmpty(questions) ? (
              <QuestionAnswerFlow
                testId={testId}
                topic_id={topic_id}
                questions={questions}
                isLanding={isLanding}
                onGetAnswerScours={onGetAnswerScours}
              />)
              :
              <div className='coming-soon'>Coming Soon...</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
