import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  CheckBox,
  DropDownSelect,
  FormInput,
  PhoneNumberInput,
  SignupContainer,
} from 'src/Components';
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import {
  NameValidation,
  EmailValidation,
  PhoneValidation,
  PasswordValidation
} from 'src/Utils/Validation';
import {
  studentSignUP,
  getDiscount,
} from 'src/Redux/Actions';

export default function SignUpPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { topic_id, plan_id } = useParams();

  const getLoadingRef = useRef(false);
  const discountLoadingRef = useRef(false);
  const {
    user, token, signUp_res, signUp_success, signUp_loading
  } = useSelector(
    state => state.Auth,
  );

  const {
    get_discount_status, get_discount_success, get_discount_loading
  } = useSelector(
    state => state.Payment,
  );

  const {
    topicList
  } = useSelector(
    state => state.Topic,
  );

  const {
    referral_code
  } = useSelector(
    state => state.Other,
  );

  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    test_pre: {
      id: '',
      name: ''
    },
    couponCode: ''
  });
  const [discount, setDiscount] = useState('');
  const [shareFriends, setShareFriends] = useState(0);

  const [userValidation, setUserValidation] = useState({
    firstNameValid: '',
    lastNameValid: '',
    emailValid: '',
    passwordValid: '',
    couponCodeValid: ''
  });

  useEffect(() => {
    if (getLoadingRef.current && !signUp_loading) {
      hideSpinner();
      if (signUp_success) {
        showToast('success', 3000, signUp_res);
        navigate(`/auth/payment/topic_id/${topic_id}/plan_id/${plan_id}/discount/${get_discount_success ? userInfo.couponCode : undefined}/share_friend/${shareFriends}`);
      } else {
        showToast('error', 3000, signUp_res);
      };
    }
    getLoadingRef.current = signUp_loading;
  }, [dispatch, signUp_loading, signUp_success, navigate, signUp_res, user, token, userInfo, topic_id, plan_id, shareFriends, get_discount_success]);

  //Get Discount res
  useEffect(() => {
    if (discountLoadingRef.current && !get_discount_loading) {
      hideSpinner();
      if (get_discount_success) {
        showToast('success', 3000, get_discount_status);
        setDiscount(get_discount_status);
      } else {
        showToast('error', 3000, get_discount_status);
      };
    }
    discountLoadingRef.current = get_discount_loading;
  }, [dispatch, get_discount_loading, get_discount_success, get_discount_status, userInfo.couponCode]);

  const onChangeUser = (field, validationField, value) => {
    setUserInfo({
      ...userInfo,
      [field]: value,
    });
    if (validationField !== 'testPreValid') {
      setUserValidation({
        ...userValidation,
        [validationField]: getValidation(field, value)
      });
    }
  };

  const getValidation = (field, value) => {
    if (field === 'first_name' || field === 'last_name')
      return NameValidation(value);
    if (field === 'email') return EmailValidation(value);
    if (field === 'phone') return PhoneValidation(value);
    return PasswordValidation(value);
  };

  const toggleShareFriend = (num) => {
    setShareFriends(num === shareFriends ? 0 : num)
  }


  const checkDisableBtn = () => {
    if (
      NameValidation(userInfo.first_name) &&
      NameValidation(userInfo.last_name) &&
      EmailValidation(userInfo.email) &&
      PhoneValidation(userInfo.phone) &&
      PasswordValidation(userInfo.password) &&
      userInfo.test_pre.id
    )
      return false;
    return true;
  }

  const onSignUp = () => {
    if (!checkDisableBtn()) {
      const payload = {
        full_name: `${userInfo.first_name} ${userInfo.last_name}`,
        email: userInfo.email,
        phone: userInfo.phone,
        password: userInfo.password,
        topic_id: userInfo.test_pre.id,
        referrer_code: referral_code || ''
      }
      showSpinner();
      dispatch(studentSignUP(payload));
    }
  };

  const onCouponApply = () => {
    const payload = {
      plan_id: plan_id,
      discount: userInfo.couponCode,
      multiple_login: 0,
      upsell_book: "",
    }
    showSpinner();
    dispatch(getDiscount(payload));
  }

  return (
    <SignupContainer
      step={5}
      title="Sign Up and Get a Plan "
      className='signup'
      plan_id={plan_id}
      topic_id={topic_id}
      discount={discount}
    >
      <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <FormInput
            placeholder={"First Name"}
            className="first-name"
            validation={userValidation.firstNameValid}
            value={userInfo.first_name}
            onChange={e => onChangeUser('first_name', 'firstNameValid', e)}
          />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <FormInput
            placeholder={"Last Name"}
            value={userInfo.last_name}
            className="last_name"
            validation={userValidation.lastNameValid}
            onChange={e => onChangeUser('last_name', 'lastNameValid', e)}
          />
        </div>
      </div>
      <div className='col-lg-12' >
        <FormInput
          placeholder={"Email"}
          value={userInfo.email}
          validation={userValidation.emailValid}
          onChange={e => onChangeUser('email', 'emailValid', e)}
        />
      </div>
      <div className='col-lg-12' >
        <PhoneNumberInput
          label={'Phone Number'}
          value={userInfo.phone}
          onChange={e => onChangeUser('phone', 'phoneValid', e)}
        />
      </div>
      <div className='col-lg-12' >
        <FormInput
          placeholder={"Password"}
          value={userInfo.password}
          type={'password'}
          validation={userValidation.passwordValid}
          onChange={e => onChangeUser('password', 'passwordValid', e)}
        />
      </div>
      <div className='col-lg-12' >
        <DropDownSelect
          label={"Test Prep"}
          placeholder={"Please Select Test Prep"}
          value={userInfo.test_pre.name}
          dropListInfo={topicList}
          onSelect={e => onChangeUser('test_pre', 'testPreValid', e)}
        />
      </div>
      <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <FormInput
            isLabel
            label={"Coupon code here"}
            placeholder={"- - - -"}
            className="coupon-code-input"
            value={userInfo.couponCode}
            onChange={e => onChangeUser('couponCode', 'couponCodeValid', e)}
          />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <button
            className={`btn-2 apply-btn ${userInfo.couponCode.length > 0 ? '' : 'disable'}`}
            disabled={!(userInfo.couponCode.length > 0)}
            onClick={onCouponApply}>
            APPLY
          </button>
        </div>
        <div className='share-friend-content v-c'>
          <CheckBox
            isCheck={!!(shareFriends === 3)}
            onClick={() => toggleShareFriend(3)}
          />
          <div className='additional-off'>Share account with up to <strong>3</strong> friends and family members (additional <strong>$9.99</strong>)</div>
        </div>
        <div className='share-friend-content v-c'>
          <CheckBox
            isCheck={!!(shareFriends === 5)}
            onClick={() => toggleShareFriend(5)}
          />
          <div className='additional-off'>Share account with up to <strong>5</strong> friends and family members (additional <strong>$14.99</strong>)</div>
        </div>
      </div>

      <div className='policy-content v-c'>
        <div className='sub-description'>
          {`I agree to the `}
          <a href="https://gradegetter.com/terms" className="tag" target="_blank" rel="noopener noreferrer">Terms</a>
          {` and the `}
          <a href="https://gradegetter.com/privacy" className="tag" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </div>
      </div>
      <div
        className={`done-btn btn-2 ${checkDisableBtn() ? 'disable' : ''}`}
        onClick={onSignUp}>
        CREATE AN ACCOUNT
      </div>
      <div className='have-account text-center'>
        <div className='sub-description'>
          {`Already have a GradeGetter account? `}
          <a href="https://gradegetter.com/sign-in" >Login</a>
        </div>
      </div>
    </SignupContainer>
  );
}
