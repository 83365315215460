import {
  GET_QUESTIONS, GET_QUESTIONS_FAIL, GET_QUESTIONS_SUCCESS,
  GET_TEST_ID, GET_TEST_ID_FAIL, GET_TEST_ID_SUCCESS,
  GET_SCORES, GET_SCORES_FAIL, GET_SCORES_SUCCESS,
  SUBMIT_ANSWER, SUBMIT_ANSWER_FAIL, SUBMIT_ANSWER_SUCCESS,

} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  getQuestions as getQuestionsApi,
  getTestId as getTestIdApi,
  getScores as getScoresApi,
  submitAnswer as submitAnswerApi,
} from 'src/Api';

/**
* Get Questions
*/
function* getQuestions(payload) {
  try {
    const result = yield getQuestionsApi(payload.topic_id);
    if (result && result.data) {
      yield put({ type: GET_QUESTIONS_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_QUESTIONS_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetQuestions() {
  yield takeLatest(GET_QUESTIONS, getQuestions);
}

/**
* Get Test id
*/
function* getTestId(payload) {
  try {
    const result = yield getTestIdApi(payload.data);
    if (result && result.data) {
      yield put({ type: GET_TEST_ID_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_TEST_ID_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetTestId() {
  yield takeLatest(GET_TEST_ID, getTestId);
}

/**
* Get Scores
*/
function* getScores(payload) {
  try {
    const result = yield getScoresApi(payload.test_id);
    if (result && result.data) {
      yield put({ type: GET_SCORES_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_SCORES_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetScores() {
  yield takeLatest(GET_SCORES, getScores);
}

/**
* Submit Answer
*/
function* submitAnswer(payload) {
  try {
    const result = yield submitAnswerApi(payload.data);
    if (result && result.data) {
      yield put({ type: SUBMIT_ANSWER_SUCCESS, data: result.data });
    } else {
      yield put({ type: SUBMIT_ANSWER_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchSubmitAnswer() {
  yield takeLatest(SUBMIT_ANSWER, submitAnswer);
}
