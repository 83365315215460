import React from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import { Partners } from './Partners';

export default function OurPartners() {

  return (
    <div className="our-partners landing-part">
      <div className="container">
        <h2>Some Universities Where Our Students Have Been Admitted.</h2>
        <div className='partners-content'>
          {Partners.map((item, index) => (
            <div className='item-content center shadow-object' key={index}>
              <ReactSVG src={item.logo} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
