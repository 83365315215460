import React from 'react';
import './style.scss';
import _ from 'lodash'
import { ReactSVG } from 'react-svg'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import add from 'src/Assets/Images/Landing/add.svg';
import { QuestionAnswer } from 'src/Components';
import { toggleQuestionModal } from 'src/Redux/Actions';

export default function QuestionAnswerModal() {
  const dispatch = useDispatch();

  const {
    isQuestionModalOpen
  } = useSelector((state) => state.Modal);

  const {
    currentTopic
  } = useSelector(
    state => state.Topic,
  );

  const closeModal = () => {
    dispatch(toggleQuestionModal(false));
  };

  return (
    <Modal show={isQuestionModalOpen} className="question-answer-modal-component">
      <div className="question-answer-modal-content v-r">
        <div className="close-btn">
          <ReactSVG src={add} className='icon-close' onClick={closeModal} />
        </div>
        <div className='question-answer-main-content'>
          <h2 className='text-center'>{currentTopic?.name}</h2>
          <QuestionAnswer
            topic_id={currentTopic?.id}
          />
        </div>
      </div>
    </Modal>
  );
}