import React, { useEffect } from 'react';
import './style.scss';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import {
  HomeStart,
  QuestionAnswer,
  BelievePractice,
  WePay,
  FreeEBook,
  // LoveUs,
  Testimonial,
  GainLifetime,
  Reviews,
  HelpYou,
  DecideNow,
  Cancelation,
  FAQs,
  OurPartners,
  // Universities,
} from 'src/Components';
import {
  SetCurrentTopic,
  setReferralCode,
} from 'src/Redux/Actions';


export default function MakeItSensePage() {
  const dispatch = useDispatch();

  let { topic_id } = useParams();

  const {
    topicList, currentTopic
  } = useSelector(
    state => state.Topic,
  );

  useEffect(() => {
    if (!_.isEmpty(topicList)) {
      const _topic = topicList.find(item => item.id === Number(topic_id));
      dispatch(SetCurrentTopic(_topic));
    }
  }, [dispatch, topicList, topic_id]);

  //Get referral code
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const _referralCode = params.get('referrer_code');
    dispatch(setReferralCode(_referralCode));
  }, [dispatch]);

  return (
    <div className="make-it-sense-page">
      <HomeStart
        currentTopic={currentTopic}
      />
      <QuestionAnswer
        topic_id={topic_id}
        isLanding
      />
      <BelievePractice
        currentTopic={currentTopic}
      />
      <WePay />
      <FreeEBook />
      {/* <LoveUs 
        currentTopic={currentTopic}
      /> */}
      <Testimonial
        topic_id={topic_id}
      />
      <GainLifetime />
      <Reviews
        topic_id={topic_id}
      />
      <HelpYou />
      <DecideNow />
      <Cancelation />
      <FAQs 
        topic_id={topic_id}
      />
      <OurPartners />
      {/* <Universities /> */}
    </div>
  );
}