import {
  FROG_UP_HUBSPOT,
  FROG_UP_HUBSPOT_SUCCESS,
  FROG_UP_HUBSPOT_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  frogUpHubSpot as frogUpHubSpotApi,
} from 'src/Api';

/**
 * Login
*/
function* frogUpHubSpot(payload) {
  try {
    const result = yield frogUpHubSpotApi(payload.data);
    if (result) {
      yield put({ type: FROG_UP_HUBSPOT_SUCCESS, data: result });
    } else {
      yield put({ type: FROG_UP_HUBSPOT_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchFrogUpHubSpot() {
  yield takeLatest(FROG_UP_HUBSPOT, frogUpHubSpot);
}
