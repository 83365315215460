import React, { useState, useEffect } from 'react';
import './style.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'src/Components';
import {
  getFaq,
} from 'src/Redux/Actions';

export default function FAQs({
  topic_id
}) {
  const dispatch = useDispatch();

  const {
    faqs,
  } = useSelector((state) => state.Other);

  useEffect(() => {
    dispatch(getFaq(topic_id));
  }, [dispatch, topic_id]);

  const [isMore, setIsMore] = useState(false);
  const [countNum, setCountNum] = useState(8);

  const onMore = () => {
    setIsMore(!isMore)
    setCountNum(isMore ? 8 : 100);
  };

  return (
    <>
      {!_.isEmpty(faqs) && (
        <div className="faqs landing-part">
          <div className="container faqs-container">
            <h2>FAQs</h2>
            <div className="faqs-list shadow-object">
              {faqs.map((item, index) => (
                index < countNum &&
                <Accordion
                  className="accordion"
                  title={item.title}
                  content={item.content}
                  key={index}
                />
              ))}
            </div>
            <div className='center'>
              <div className='btn-2 more-btn' onClick={onMore}>{isMore ? 'LESS' : 'MORE'}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}