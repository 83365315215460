import { Routes, Route } from 'react-router-dom';
import { TopMenu, Header, Footer } from 'src/Components';
import {
  LandingPage,
  MakeItSensePage,
  // PlanPage,
} from 'src/Pages';

function LandingRoutes() {
  return (
    <div>
      <TopMenu />
      <Header />
      <div className='main-content'>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/make-it-make-sense/topic_id/:topic_id" element={<MakeItSensePage />} />
          {/* <Route exact path="/plan" element={<PlanPage />} /> */}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default LandingRoutes;
