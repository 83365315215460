import React, { useEffect } from 'react';
import './style.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Plan } from 'src/Components';
// import { togglePlanModal } from 'src/Redux/Actions';
import {
  getPlan,
} from 'src/Redux/Actions';

export default function SignupContainer({
  className = '',
  title = '',
  description,
  children,
  plan_id,
  topic_id,
  discount
}) {
  const dispatch = useDispatch();

  const {
    plans
  } = useSelector((state) => state.Plan);

  useEffect(() => {
    if (_.isEmpty(plans)) dispatch(getPlan({ topic_id }));
  }, [dispatch, plans, topic_id]);

  const getInfo = () => {
    const currentPlanIndex = plans.findIndex(temp => temp.id === Number(plan_id));
    if (currentPlanIndex > -1) {
      return plans[currentPlanIndex]
    } else {
      return false
    }
  }

  // const onUPdate = () => dispatch(togglePlanModal(true));

  return (
    <div className={`signup-container`}>
      <div className='container'>
        <div className='header-section'>
          <h2>{title}</h2>
          <h2 className='free-trial'>{`(7 Days Free Trial)`}</h2>
          {description && <div className='page-description'>{description}</div>}
        </div>
        <div className="row believe-practice-content mx-auto">
          <div className={`col-lg-8 left-content ${className}`}>
            {children}
          </div>
          <div className="col-lg-4 right-content">
            {getInfo() && <Plan
              info={getInfo()}
              discount={discount}
              isSelected
            />}
            {/* <div className='btn-2 mt-4' onClick={onUPdate}>UPDATE</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
