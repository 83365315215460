import {
  GET_QUESTIONS, GET_QUESTIONS_FAIL, GET_QUESTIONS_SUCCESS,
  GET_TEST_ID, GET_TEST_ID_FAIL, GET_TEST_ID_SUCCESS,
  GET_SCORES, GET_SCORES_FAIL, GET_SCORES_SUCCESS,
  SUBMIT_ANSWER, SUBMIT_ANSWER_FAIL, SUBMIT_ANSWER_SUCCESS,
} from '../Type';

const InitialScore = {
  score: 0,
  pending_question: 0,
  correct: 0,
  wrong: 0,
  saved: 0,
}

const INITIAL = {
  questions: [],
  getQuestions_success: false,
  getQuestions_loading: false,

  testId: null,
  get_testId_success: false,
  get_testId_loading: false,

  scores: InitialScore,
  get_scores_success: false,
  get_scores_loading: false,

  submitAnswerStatus: null,
  get_submitAnswer_success: false,
  get_submitAnswer_loading: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get Questions
     */
    case GET_QUESTIONS: {
      return {
        ...state,
        questions: [],
        getQuestions_success: false,
        getQuestions_loading: true,

      }
    }
    case GET_QUESTIONS_FAIL: {
      return {
        ...state,
        questions: [],
        getQuestions_success: false,
        getQuestions_loading: false,
      }
    }
    case GET_QUESTIONS_SUCCESS: {
      return {
        ...state,
        questions: action.data,
        getQuestions_success: true,
        getQuestions_loading: false,
      }
    }

    /**
     * Get Test id
     */
    case GET_TEST_ID: {
      return {
        ...state,
        testId: null,
        get_testId_success: false,
        get_testId_loading: true,

      }
    }
    case GET_TEST_ID_FAIL: {
      return {
        ...state,
        testId: null,
        get_testId_success: false,
        get_testId_loading: false,
      }
    }
    case GET_TEST_ID_SUCCESS: {
      return {
        ...state,
        testId: action.data.test_id,
        get_testId_success: true,
        get_testId_loading: false,
      }
    }

    /**
     * Get Scours 
     */
    case GET_SCORES: {
      return {
        ...state,
        scores: InitialScore,
        get_scores_success: false,
        get_scores_loading: true,

      }
    }
    case GET_SCORES_FAIL: {
      return {
        ...state,
        scores: InitialScore,
        get_scores_success: false,
        get_scores_loading: false,
      }
    }
    case GET_SCORES_SUCCESS: {
      return {
        ...state,
        scores: action.data,
        get_scores_success: true,
        get_scores_loading: false,
      }
    }

    /**
     * Submit Answer
     */
    case SUBMIT_ANSWER: {
      return {
        ...state,
        submitAnswerStatus: null,
        get_submitAnswer_success: false,
        get_submitAnswer_loading: true,

      }
    }
    case SUBMIT_ANSWER_FAIL: {
      return {
        ...state,
        submitAnswerStatus: null,
        get_submitAnswer_success: false,
        get_submitAnswer_loading: false,
      }
    }
    case SUBMIT_ANSWER_SUCCESS: {
      return {
        ...state,
        submitAnswerStatus: action.data.is_correct,
        get_submitAnswer_success: true,
        get_submitAnswer_loading: false,
      }
    }

    default:
      return state;
  }
}