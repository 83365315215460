import {
  GET_BRAINTREE_KEY, GET_BRAINTREE_KEY_SUCCESS, GET_BRAINTREE_KEY_FAIL,
  NONCE_UPDATE, NONCE_UPDATE_SUCCESS, NONCE_UPDATE_FAIL,
  UPDATE_PURCHASE, UPDATE_PURCHASE_SUCCESS, UPDATE_PURCHASE_FAIL,
  GET_DISCOUNT, GET_DISCOUNT_SUCCESS, GET_DISCOUNT_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  getBraintreeKey as getBraintreeKeyApi,
  nonceUpdate as nonceUpdateApi,
  updatePurchase as updatePurchaseApi,
  getDiscount as getDiscountApi,
} from 'src/Api';

/**
* Get Braintreee Key
*/
function* getBraintreeKey() {
  try {
    const result = yield getBraintreeKeyApi();
    if (result.token) {
      yield put({ type: GET_BRAINTREE_KEY_SUCCESS, data: result });
    } else {
      yield put({ type: GET_BRAINTREE_KEY_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetBraintreeKey() {
  yield takeLatest(GET_BRAINTREE_KEY, getBraintreeKey);
}

/**
 * Update Nonce
*/
function* nonceUpdate(payload) {
  try {
    const result = yield nonceUpdateApi(payload.data);
    if (!result.error) {
      yield put({ type: NONCE_UPDATE_SUCCESS, data: result });
    } else {
      yield put({ type: NONCE_UPDATE_FAIL, data: result });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchNonceUpdate() {
  yield takeLatest(NONCE_UPDATE, nonceUpdate);
}

/**
 * Update Purchase
*/
function* updatePurchase(payload) {
  try {
    const result = yield updatePurchaseApi(payload.data);
    if (result && result.data.error === false) {
      yield put({ type: UPDATE_PURCHASE_SUCCESS, data: result.data });
    } else {
      yield put({ type: UPDATE_PURCHASE_FAIL, data: result.data });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchUpdatePurchase() {
  yield takeLatest(UPDATE_PURCHASE, updatePurchase);
}

/**
 * Get Discount
*/
function* getDiscount(payload) {
  try {
    const result = yield getDiscountApi(payload.data);
    if (result && result.data && !result.data.error) {
      yield put({ type: GET_DISCOUNT_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_DISCOUNT_FAIL, data: result.data });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetDiscount() {
  yield takeLatest(GET_DISCOUNT, getDiscount);
}
