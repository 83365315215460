import {
  GET_QUESTIONS,
  GET_TEST_ID,
  GET_SCORES,
  SUBMIT_ANSWER,
} from '../Type';

export const getQuestions = (topic_id) => {
  return {
    type: GET_QUESTIONS,
    topic_id
  }
}

export const getTestId = (data) => {
  return {
    type: GET_TEST_ID,
    data
  }
}

export const getScores = (test_id) => {
  return {
    type: GET_SCORES,
    test_id
  }
}

export const submitAnswer = (data) => {
  return {
    type: SUBMIT_ANSWER,
    data
  }
}