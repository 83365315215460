import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Plan } from 'src/Components';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';
import {
  togglePlanModal,
  getPlan,
} from 'src/Redux/Actions';

export default function MPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getLoadingRef = useRef(false);

  const {
    plans, get_plans_loading, get_plans_success,
  } = useSelector((state) => state.Plan);

  const {
    currentTopic
  } = useSelector(
    state => state.Topic,
  );

  useEffect(() => {
    showSpinner();
    dispatch(getPlan({ topic_id: currentTopic.id }));
  }, [dispatch, currentTopic]);

  const [selectedPlanId, setSelectedPlanId] = useState(2);

  //Get Questions res
  useEffect(() => {
    if (getLoadingRef.current && !get_plans_loading) {
      hideSpinner();
      if (get_plans_success) {
        // console.log("===plans===", plans)
      }
    }
    getLoadingRef.current = get_plans_loading;
  }, [get_plans_loading, get_plans_success, plans]);

  const onChosePlan = (item) => {
    setSelectedPlanId(item.id)
    dispatch(togglePlanModal(false));
    navigate(`/auth/sign-up/topic_id/${currentTopic.id}/plan_id/${item.id}`);
  };

  return (
    <div className="m-plan-component">
      <div className="container">
        <h2 className='text-center'>Try Any Plan Risk-Free For 7 Days</h2>
        <div className="description text-center">{`Continue practicing ${currentTopic?.tag} so it makes sense.`}</div>
        <div className="plan-content">
          {!_.isEmpty(plans) && plans.map((item, index) =>
            <Plan
              key={index}
              info={item}
              selectedPlanId={selectedPlanId}
              onChosePlan={onChosePlan}
              currentTopic={currentTopic}
            />
          )}
        </div>
        <div className='already-account'>
          Already have an account? 
          <a href="https://gradegetter.com/sign-in" className="tag login" rel="noopener noreferrer">Login</a>
         here</div>
      </div>
    </div>
  );
}
