import { GET_SUBJECTS, GET_SUBJECTS_SUCCESS, GET_SUBJECTS_FAIL } from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import { getSubjects as getSubjectsApi } from 'src/Api';

function* getSubjects(payload) {
  try {
    const result = yield getSubjectsApi(payload.level_id);

    if (result.data) {
      yield put({ type: GET_SUBJECTS_SUCCESS, data: result.data });
    } else {
      yield put({ type: GET_SUBJECTS_FAIL, data: [] });
    }
  } catch (err) {
    alert(err);
  }
}

export function* watchGetSubjects() {
  yield takeLatest(GET_SUBJECTS, getSubjects);
}
