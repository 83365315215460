import React from 'react';
import './style.scss';

export default function UpSellingContainer({
  className = '',
  title,
  children
}) {

  return (
    <div className={`up-selling-container-component ${className}`}>
      <h1>{title}</h1>
      <div className="up-selling-list-content">
        {children}
      </div>
    </div>
  );
}