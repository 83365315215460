import tcu from "src/Assets/Images/Landing/Partners/tcu.svg"
import harvard from "src/Assets/Images/Landing/Partners/harvard-university.svg"
import nyu from "src/Assets/Images/Landing/Partners/nyu.svg"
import yale from "src/Assets/Images/Landing/Partners/yale-university.svg"
import texas from "src/Assets/Images/Landing/Partners/texas-university.svg"
import stanford from "src/Assets/Images/Landing/Partners/stanford-university.svg"
import usc from "src/Assets/Images/Landing/Partners/usc.svg"
import lsu from "src/Assets/Images/Landing/Partners/lsu.svg"
// import bbc from "src/Assets/Images/Landing/Partners/bbc.svg"
// import trustpilot from "src/Assets/Images/Landing/Partners/trustpilot.svg"
// import consumer from "src/Assets/Images/Landing/Partners/consumer.svg"
// import google from "src/Assets/Images/Landing/Partners/google.svg"

export const Partners = [
  { id: 1, logo: tcu },
  { id: 2, logo: harvard },
  { id: 3, logo: nyu },
  { id: 4, logo: yale },
  { id: 5, logo: texas },
  { id: 6, logo: stanford },
  { id: 7, logo: usc },
  { id: 8, logo: lsu },
  // { id: 9, logo: bbc },
  // { id: 10, logo: trustpilot },
  // { id: 11, logo: consumer },
  // { id: 12, logo: google },
];