import React from 'react';
import './style.scss';
import CancelationImg from 'src/Assets/Images/Landing/Cancelation/cancelation.svg';

export default function Cancelation() {

  return (
    <div className="cancelation-component landing-part">
      <div className="container">
        <div className="row cancelation-content">
          <div className="col-lg-7 left-content">
            <div className='cancelation-content'>
              <h1>100% Money-Back Guarantee</h1>
              <h2>You only pay if you love it</h2>
              <p>
                We process refunds within 24hrs. You can request a refund for up to 30 days after the purchase date, which should give you plenty of time to decide if our course is right for you. Simply email us at info@gradegetter.com or pay us a visit at 1751 River Run Suite 200 Fort Worth, TX 76107.
              </p>
            </div>
          </div>
          <div className="col-lg-5 center right-content show-web">
            <img src={CancelationImg} alt='cancelation-img' className='cancelation-img' />
          </div>
        </div>
      </div>
    </div>
  );
}