import React from 'react';
import './style.scss';
// import ActiveUpSellImg from 'src/Assets/Images/UpSelling/active-up-selling-img.svg'
// import InActiveUpSellImg from 'src/Assets/Images/UpSelling/inActive-up-selling-img.svg'
import CheckActiveIcon from 'src/Assets/Images/UpSelling/check-active-icon.svg'
import CheckInActiveIcon from 'src/Assets/Images/UpSelling/check-inactive-icon.svg'

export default function UpSellingItem({
  isUpSell,
  info,
  onChange
}) {

  const {
    id,
    name,
    description,
    picture,
    original_price,
    current_price,
  } = info

  return (
    <div className="up-selling-item-component v-c">
      <img
        src={isUpSell ? picture : picture}
        alt="up-sell-img"
        className="up-sell-img show-web"
      />
      <div className={`up-selling-detail v-c ${isUpSell ? 'active' : ''}`}>
        <div className='title-description'>
          <div className='title'>{name}</div>
          <div className='description'>{description}</div>
        </div>
        <div className=''>
          {current_price !== original_price && <div className='origin-price'>{`$${original_price}`}</div>}
          <div className='price'>{`$${current_price}`}</div>
        </div>
        <img
          src={isUpSell ? CheckActiveIcon : CheckInActiveIcon}
          alt="check-icon"
          className="check-icon cursor-pointer"
          onClick={() => onChange(id)}
        />
      </div>
    </div>
  );
}