import React from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import WePayImg from 'src/Assets/Images/Landing/WePay/we-pay-right-img.png';
import { togglePlanModal } from 'src/Redux/Actions';

export default function WePay() {
  const dispatch = useDispatch();

  const onUpgrade = () => dispatch(togglePlanModal(true));

  return (
    <div className="we-pay-component landing-part">
      <div className="container">
        <div className="row pay-now-content">
          <div className="col-lg-7 left-content">
            <h1>We Pay <strong>You $149</strong> If You Don’t Raise Your <strong>Math Score</strong> in Under <strong>2 Weeks</strong></h1>
            <h3>And you still keep the program</h3>
            <div className='comment-section'>
              With our <strong>fail-proof guarantee,</strong> you can be just as confident as we are that you will see results with our program. In fact, if you don’t see an improvement in under 2 weeks, not only will <strong>you get $149,</strong> but you can still keep the program. This is as <strong>risk-free</strong> as it gets.
            </div>
            <h3>So stop debating - let’s achieve your <strong>dream score</strong> together!</h3>
            <div className='btn-2 upgrade-btn' onClick={onUpgrade} >
              RAISE YOUR MATH SCORE
            </div>
          </div>
          <div className="col-lg-5 right-content show-web">
            <img src={WePayImg} alt='we-pay-right-img' className='we-pay-right-img show-web' />
          </div>
        </div>
      </div>
    </div>
  );
}