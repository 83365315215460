import {
  GET_FAQ,
  SET_REFERRAL_CODE,
  SET_QUESTION_STEP
} from '../Type';

export const getFaq = (mims_faqcategory_id) => {
  return {
    type: GET_FAQ,
    data: { mims_faqcategory_id }
  }
}

export const setReferralCode = (referral_code) => {
  return {
    type: SET_REFERRAL_CODE,
    data: { referral_code }
  }
}

export const setQuestionStep = (questionStep) => {
  return {
    type: SET_QUESTION_STEP,
    data: { questionStep }
  }
}
