import { GET_SUBJECTS, GET_SUBJECTS_FAIL, GET_SUBJECTS_SUCCESS } from '../Type';

const INITIAL = {
  subjects: [],
  get_subjects_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_SUBJECTS:
    case GET_SUBJECTS_FAIL: {
      return {
        subjects: [],
        get_subjects_success: false,
      }
    }
    case GET_SUBJECTS_SUCCESS: {
      let subjects = action.data || [];
      return {
        subjects: subjects,
        get_subjects_success: true,
      }
    }

    default:
      return state;
  }
}