import React from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import BIcon from 'src/Assets/Images/Landing/HelpYou/B.svg';
import BookImg from 'src/Assets/Images/Landing/HelpYou/book-web.svg';
import BookMobileImg from 'src/Assets/Images/Landing/HelpYou/book-mobile.svg';
import { togglePlanModal } from 'src/Redux/Actions';

export default function HelpYou() {
  const dispatch = useDispatch();

  const onSignup = () => dispatch(togglePlanModal(true));

  return (
    <div className="help-you-component landing-part">
      <div className="container">
        <div className="row help-you-content">
          <div className="col-lg-7 left-content">
            <div className='decide-content'>
              <h1><strong>FREE:</strong> Hacking College Admissions & Essays</h1>
              <h3>Written by Ivy League Alumni and Admissions Officers, these eBooks offer unparalleled guidance on college admissions</h3>
              <div className='detail-list'>
                <div className='detail-item'>
                  <img src={BIcon} alt='b-icon' className='b-icon' />
                  <div>
                    <div className='list-sub-title'>eBook: Hacking College Admissions</div>
                    <p>
                      Victor Agbafe and Harold Ekeh achieved the impossible - they were accepted into the Ivy League Schools. Not just 1 or 2 or 3… All of them. Stanford, Harvard, Yale, etc. were all FIGHTING to get Victor and Harold to come to their school. As a result, they were both awarded over $4 million in scholarships. In “Hacking College Admissions,” they reveal how they did it, and how you can follow in their footsteps.
                    </p>
                    <p>
                      And we are offering this book for FREE with our course. If you got even 1 small scholarship from this book, it would cover your ENTIRE cost for this course and then some.
                    </p>
                  </div>
                </div>
                <div className='detail-item'>
                  <img src={BIcon} alt='b-icon' className='b-icon' />
                  <div>
                    <div className='list-sub-title'>eBook: 60 Successful Ivy League Application Essays</div>
                    <p>
                      Ever wonder what essays got students into Ivy League Schools? Or why admissions officers reject an application? Well, those questions and more are answered within this book. With 60 exceptional Ivy League essays to read and advice from admissions officers themselves, this book can singlehandedly help you write outstanding college essays and avoid critical mistakes in your application.
                      Did we already mention that this book is free too?
                    </p>
                  </div>
                </div>
              </div>
              <h3>These books are <strong>valued at $72,</strong> which is over 50% of the cost of this course and we are giving them to you as a <strong>BONUS</strong>. Don't wait until we realize that we're crazy for doing this. Click below before we come to our senses.</h3>
            </div>
            <div className='btn-2 upgrade-btn' onClick={onSignup} >
              SIGN UP FOR PLAN AND GET THE BONUS
            </div>
          </div>
          <div className="col-lg-5 right-content">
            <img src={BookImg} alt='book-img' className='book-img show-web' />
            <img src={BookMobileImg} alt='book-img' className='book-img show-mobile' />
          </div>
        </div>
      </div>
    </div>
  );
}