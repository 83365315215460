import React, { useEffect } from 'react';
import './style.scss';
import _ from 'lodash';
import { ReactSVG } from 'react-svg'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'
import CloseIcon from 'src/Assets/Images/Landing/close-icon.svg';
import {
  QuestionAnswer,
} from 'src/Components';
import {
  SetCurrentTopic,
} from 'src/Redux/Actions';

export default function QuestionAnswerPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { topic_id } = useParams();

  const {
    topicList, currentTopic
  } = useSelector(
    state => state.Topic,
  );

  useEffect(() => {
    if (!_.isEmpty(topicList)) {
      const _topic = topicList.find(item => item.id === Number(topic_id));
      dispatch(SetCurrentTopic(_topic));
    }
  }, [dispatch, topicList, topic_id]);

  const onCloseQuestion = () => {
    navigate(-1);
   }

  return (
    <div className="question-answer-page">
      <ReactSVG src={CloseIcon} className='icon-close' onClick={onCloseQuestion} />
      <h2 className='text-center'>{currentTopic?.name}</h2>
      <QuestionAnswer
        topic_id={topic_id}
      />
    </div>
  );
}