import {
  TOGGLE_REVIEW_MODAL,
  TOGGLE_VIDEO_MODAL,
  TOGGLE_PRACTICE_MODAL,
  TOGGLE_PLAN_MODAL,
  TOGGLE_QUESTION_MODAL,
  TOGGLE_NOTE_MODAL,
} from '../Type';

const INITIAL = {
  isReviewModal: false,
  reviewInfo: {},
  isVideoModal: false,
  videoInfo: {},
  isPracticeModal: false,
  isPlanModal: false,
  isQuestionModalOpen: false,
  isNoteModal: false,
  note: "",
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Review Modal
     */
    case TOGGLE_REVIEW_MODAL: {
      const { isReviewModal, reviewInfo } = action.data;
      return {
        ...state,
        isReviewModal,
        reviewInfo,
      };
    }

    /**
     * Video Modal
     */
    case TOGGLE_VIDEO_MODAL: {
      const { isVideoModal, videoInfo } = action.data;
      return {
        ...state,
        isVideoModal,
        videoInfo,
      };
    }

    /**
     * Practice Modal
     */
    case TOGGLE_PRACTICE_MODAL: {
      const { isPracticeModal } = action.data;
      return {
        ...state,
        isPracticeModal,
      };
    }

    /**
     * Plan Modal
     */
    case TOGGLE_PLAN_MODAL: {
      const { isPlanModal } = action.data;
      return {
        ...state,
        isPlanModal, 
      };
    }

    /**
     * Question and Answer Modal
     */

    case TOGGLE_QUESTION_MODAL: {
      const { isOpen } = action.data;
      return {
        ...state,
        isQuestionModalOpen: isOpen,
      };
    }

    /**
     * Note Modal
     */
    case TOGGLE_NOTE_MODAL: {
      const { isNoteModal, note } = action.data;
      return {
        ...state,
        isNoteModal,
        note,
      };
    }

    default:
      return state;
  }
}