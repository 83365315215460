import React, { useEffect } from 'react';
import './style.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideoModal } from 'src/Redux/Actions';
import {
  getMimsReviews,
} from 'src/Redux/Actions';
import TestimonialItem from './TestimonialItem';

import Media from "react-media";
import { CarouselLeftButton, CarouselRightButton } from "src/Constant";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Testimonial({
  topic_id
}) {
  const dispatch = useDispatch();

  const {
    m_reviews,
  } = useSelector((state) => state.ReviewT);

  useEffect(() => {
    dispatch(getMimsReviews(topic_id));
  }, [dispatch, topic_id]);

  const onPlayVideo = (videoId) => dispatch(toggleVideoModal(true, { videoId }));

  return (
    <>
      {!_.isEmpty(m_reviews) && (
        <div className="testimonial-component landing-part">
          <div className="container">
            <h2>Testimonials</h2>
            <div className="testimonial-content mx-auto">
              <Media queries={{
                lg: "(min-width: 1200px)",
                md: "(min-width: 992px) and (max-width: 1199px)",
              }}>
                {matches => (
                  <OwlCarousel
                    className="owl-theme"
                    rewind
                    nav
                    stagePadding={matches.lg ? 20 : matches.md ? 20 : 15}
                    margin={matches.lg ? 28 : matches.md ? 33 : 20}
                    items={1}
                    dots={false}
                    loop={false}
                    navText={[CarouselLeftButton, CarouselRightButton]}
                  >
                    {m_reviews.map((item, index) =>
                      <TestimonialItem
                        key={index}
                        info={item}
                        index={index}
                        onPlayVideo={onPlayVideo}
                      />
                    )}
                  </OwlCarousel>
                )}
              </Media>
            </div>
          </div>
        </div>
      )}
    </>
  );
}