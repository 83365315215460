import React from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { ReactSVG } from "react-svg";
import { Score } from 'src/Components';
import LockIcon from "src/Assets/Images/Landing/lock-icon.svg";
import LockLgIcon from "src/Assets/Images/Landing/lock-icon-lg.svg";
import Logo from "src/Assets/Images/Logo/logo-white-2.svg";
import { togglePlanModal } from 'src/Redux/Actions';


export default function Scores({
  className = '',
  testId,
  scores
}) {
  const dispatch = useDispatch();

  const {
    // score,
    // pending_question,
    // correct,
    wrong,
    saved,
  } = scores

  const onUpgrade = () => dispatch(togglePlanModal(true));

  return (
    <div className={`scores-component ${className}`}>
      <div className='score-content shadow-object'>
        <Score
          title="Score"
          // value={score}
          value={3}
          max={20}
        />
        <Score
          title="Pending Questions"
          // value={pending_question}
          value={30}
          max={50}
          barColor={"#FFDF6E"}
        />
        <Score
          title="Correct"
          // value={correct}
          value={5}
          max={20}
          barColor={"#8ED37D"}
        />
        <Score
          title="Wrong"
          value={wrong}
        />
        <Score
          title="Saved"
          value={saved}
        />
        <div className='lock-status'>
          <img src={LockLgIcon} alt='lock-lg-icon' className='lock-lg-icon' />
          <div className='free-account'>Create a free account to track results</div>
          <div className='btn-2 create-account-btn' onClick={onUpgrade}>Try it Free For 7 Days</div>
          <img src={Logo} alt='logo-icon' className='logo-icon' />
        </div>
      </div>
      <div className='plan-status v-c space-between'>
        <div className='lock-container v-c'>
          <div className='lock-content center'>
            <ReactSVG src={LockIcon} />
          </div>
          <div>
            <div className='plan-status-title'>PLAN STATUS</div>
            <div className='lock-title'>LIMITED</div>
          </div>
        </div>
        <div className='btn-2 upgrade-btn' onClick={onUpgrade}>Try it Free For 7 Days</div>
      </div>
    </div>
  );
}
