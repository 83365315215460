import { Api } from './Api';

export function* getAllReviews(Topic_id) {
  const jsonData = yield Api.get(`/mimstests?topic_id=${Topic_id}`);
  return jsonData;
}

export function* getMimsReviews(topic_id) {
  const jsonData = yield Api.get(`/mimsreviews?topic_id=${topic_id}`);
  return jsonData;
}


