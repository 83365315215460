import {
  GET_PLAN, GET_PLAN_SUCCESS, GET_PLAN_FAIL
} from '../Type';

const INITIAL = {
  get_plans_loading: false,
  get_plans_success: false,
  plans: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get Plan
     */
    case GET_PLAN: {
      return {
        ...state,
        get_plans_loading: true,
        get_plans_success: false,
      }
    }
    case GET_PLAN_FAIL: {
      return {
        plans: [],
        get_plans_loading: false,
        get_plans_success: false,
      }
    }
    case GET_PLAN_SUCCESS: {
      return {
        plans: action.data,
        get_plans_loading: false,
        get_plans_success: true,
      }
    }

    default:
      return state;
  }
}